export const state = {
	activeFormsList:[],
	draftFormsList:[],
	fillableForm:[],
	fieldsInventoryList:[],
	formFunctionsList:[],
	dataCategoriesList:[],
	fromTypesList:[],
	taskModulesList:[],
	submissionsList:[],
	formGroupsList:[],
	formHeadersList:[],
	scoringCategoriesList:[],
	taskCategoriesList:[],
	formSectionList:[],
	sectionList:[],
	formReviewsList:[],	
	userGroupsList:[],
	formTaskTypesList:[], //task types
	subsections: [],
  	questions: [],
	section: null,
	formSections: [],
	formSubSections: [],
	formQuestions: [],
	formQuestionOptions: [],
	forms: [],
	questionTypesList: [],
	parentQuestionsList: [],
	parentOptionsList: [],
	feedbackFormList: [],
	followUpFormList: [],
	form: null,
    form_dropdowns: {},


}

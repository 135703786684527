import { state } from './state';
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';


export default {
    namespaced: false,
    state: {
        forms: [],
        currentForm: null,
        isLoading: false,
        error: null,
    },
    mutations,
    actions,
    getters
}
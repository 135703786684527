import { checkPermissions } from '@/utils/routeService/useCheckPermissions';


export default [
    {
        path: '/nurses',
        name: 'Nurse',
        component: () =>
            import('@/views/nurses/index.vue'),
        meta: {
            check: 'show_nurses',
        },
        beforeEnter: checkPermissions,
    },
    {
        path: '/nurses/add',
        name: 'Add Nurse',
        component: () =>
            import('@/views/nurses/create'),
    },
    // {
    //     path: '/nurse/:id',
    //     component: () =>
    //         import ('@/views/nurses/View.vue'),
    // },
    {
        path: '/nurses/update/:id',
        name: 'Nurse Update',
        component: () =>
            import('@/views/nurses/edit'),
    },

    // {
    //     path: '/nurses/view/:id',
    //     name: 'View Nurse Details',
    //     component: () =>
    //         import ('@/views/nurses/View.vue'),
    // },

]
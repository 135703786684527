<template>
  <div>
    <!-- Content here -->
    <router-view />
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { usePermissions } from '@/gates/usePermissions';
import { setUserPerms } from '@/gates/permissions'; 
import { useLoading } from "vue-loading-overlay";

const store = useStore();
const $loading = useLoading();

// const { setUserPerms } = usePermissions();

onMounted(async () => {
  try {
    const permissions = await store.dispatch('authUserSystemPermissions');
    // console.log("inside App.vue", permissions.data);
    setUserPerms(permissions);

  } catch (error) {
    console.error('Failed to fetch user permissions:');
    //console.error('Failed to fetch user permissions:', error);
  }
});


</script>

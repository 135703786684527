

export const actions = {


    updateSidebar({ commit }, payload) {
        commit('setSidebarStatus', payload);
    },

}


// People submitted form 
import {
    fetchPeopleSubmittedFormsRequest
} from '@/utils/services/people_management/forms/submittedForms';

// form responses
import {
    savePeopleFormResponseRequest,
    fetchPeopleFormResponsesRequest,
    fetchPeopleFormResponseRequest,
    updatePeopleFormResponseRequest,
    deletePeopleFormResponseRequest,
} from '@/utils/services/people_management/forms/formResponses';

export const actions = {
    // fetch people mgt submitted forms
    async fetchPeopleSubmittedForms({ commit }) {
        try {
            commit('SET_LOADING', true);
            const response = await fetchPeopleSubmittedFormsRequest();
            if (response?.data?.success) {
                commit('SET_FORMS', response.data.data);
                return response.data.data;
            }
            throw new Error(response?.data?.message || 'Failed to fetch forms');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // save form
    async savePeopleFormResponse({ commit, state }, formData) {
        try {
            commit('SET_LOADING', true);
            const response = await savePeopleFormResponseRequest(formData);
            // console.log('Action: ' + response);
            if (response?.data?.success) {
                if (state.currentFormResponse?.id) {
                    // Update existing form response
                    commit('UPDATE_FORM_RESPONSES', {
                        formId: state.currentFormResponse.id,
                        responses: formData.responses
                    });
                }
                commit('SET_CURRENT_FORM', response.data.data);
                commit('SET_SUBMISSION_STATUS', true);
            } else {
                console.log('Action: ', response)
            }
            return response;
        } catch (error) {
            commit('SET_ERROR', error?.response?.data?.message);
            throw error.response;
        } finally {
            commit('SET_LOADING', false);
        }
    },


    // fetch people forms
    async fetchPeopleFormResponses({ commit }) {
        try {
            commit('SET_LOADING', true);
            const response = await fetchPeopleFormResponsesRequest();
            // console.log('API Response:', response.data.data);
            if (response?.data?.success) {
                commit('SET_FORMS', response.data.data);
                return response.data.data;
            }
            throw new Error(response?.data?.message || 'Failed to fetch forms');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // fetch form by id
    async fetchFormResponseById({ commit }, id) {
        try {
            commit('SET_LOADING', true);
            const response = await fetchPeopleFormResponseRequest(id);
            if (response?.data?.success) {
                commit('setPeopleFormQuestionResponse', response.data.data);
                return response.data.data;
            }
            throw new Error(response?.data?.message || 'Failed to fetch form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },


    // update form
    async updatePeopleFormResponse({ commit }, payload) {
        try {
            commit('SET_LOADING', true);
            const response = await updatePeopleFormResponseRequest(payload);
            if (response?.data?.success) {
                commit('UPDATE_FORM_SETUP', payload);
                // console.log(response.data.success);
                return response.data.data;
            }
            // throw new Error(response?.data?.message || 'Failed to update form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // delete form
    async deletePeopleFormResponse({ commit }, id) {
        try {
            commit('SET_LOADING', true);
            const response = await deletePeopleFormResponseRequest({ id: id });
            if (response?.data?.success) {
                return response.data;
            }
            throw new Error(response?.data?.message || 'Failed to delete form response');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async submitFormResponse({ commit }, id) {
        try {
            commit('SET_LOADING', true);
            const response = await submitFormResponseRequest(id);
            if (response?.data?.success) {
                commit('SET_SUBMISSION_STATUS', false);
                return response.data.data;
            }
            throw new Error(response?.data?.message || 'Failed to submit form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },


    startNewFormSubmission({ commit }) {
        commit('SET_CURRENT_FORM', null);
        commit('SET_SUBMISSION_STATUS', false);
    }
};
import axios from "axios";
import config from "@/config";
const { baseAPI, Header } = config;

const requestURI = 'people_management/settings/conditional-question';


export const fetchPeopleConditionalQuestionsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        throw error.response || error;
    }
}

export const fetchPeopleConditionalQuestionRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        throw error.response || error;
    }
}

export const savePeopleConditionalQuestionRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        throw error.response || error;
    }
}

export const updatePeopleConditionalQuestionRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/update`, payload, Header);
    } catch (error) {
        throw error.response || error;
    }
}

export const deletePeopleConditionalQuestionRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/delete`, payload, Header);
    } catch (error) {
        throw error.response || error;
    }

}


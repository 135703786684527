import * as api from '@/utils/services/permission';

const state = {
    permissions: {},
    roleUsers: {},
    userNotInRole: {},
    roles: {},
    usersInRoles: {},
    permHeaders: {},
    basicPermHeaders: {},
    authUserPerms: {},
}

const getters = {
    listPermissions: state => state.permissions,
    listRoleUsers: state => state.roleUsers,
    listUserNotInRole: state => state.userNotInRole,
    listAllRoles: state => state.roles,
    listUsersInRoles: state => state.usersInRoles,
    listPermHeaders: state => state.permHeaders,
    listBasicPermHeaders: state => state.basicPermHeaders,
    listAuthUserPerms: state => state.authUserPerms,
}

const actions = {

    async updatePermission({ commit }, params) {
        try {
            const response = await api.updateUserPermission(params.data, params.id);

            return response;
        } catch (error) {
            throw error.response;
        }
    },

    async fetchAllPermissions({ commit }) {
        try {
            const response = await api.getPermissions();
            commit('setPermissions', response.data);
        } catch (error) {

        }
    },

    async fetchAllRoles({ commit }) {
        try {
            const response = await api.getAllRoles();
            if (response.status === 200) {
                this.items = response.data;
                this.loading = false;
            } else {
                this.error = true;
            }
            commit('setAllRoles', response.data);
        } catch (error) {
            this.error = true;

        }
    },

    async createUserRole({ commit }, params) {
        try {
            const response = await api.storeRole(params);
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async updateRole({ commit }, params) {
        try {
            const response = await api.updateUserRole(params);
            if (response.status === 200) {
                this.items = response.data;
                this.loading = false;
            } else {
                this.error = true;
            }
            console.log('updateUserRole ' + response.data);
            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async fetchRoleUsers({ commit }, roleId) {
        try {
            const response = await api.getRoleUser(roleId);
            commit('setRoleUser', response.data);
        } catch (error) {
            console.log(error);
        }
    },

    async fetchUsersInRoles({ commit }, params) {
        try {
            const response = await api.getUsersInRoles(params);
            commit('setUsersInRoles', response.data);
        } catch (error) {
            console.log(error);
        }
    },

    async fetchRoleUsersTask({ commit }, params) {
        try {
            const response = await api.getRoleUserTask(params);
            commit('setRoleUser', response.data);
        } catch (error) {
            console.log(error);
        }
    },

    async fetchUsersNotInRole({ commit }, { roleId, search }) {
        try {
            const response = await api.getRoleUserNotIn(roleId, search);
            commit('setUserNotInRole', response.data);
            return response.data;
        } catch (error) {

            throw error.response;
        }
    },



    async updateUserRole({ commit }, params) {
        try {
            const response = await api.updateUserRole(params);
            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async editUserRole({ commit }, params) {
        try {
            const response = await api.editUserRole(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    },


    //permissions ---- not system permissions -- this handles the sidebar permissions
    async updateUserRolePermission({ commit }, params) {
        try {
            const response = await api.updateUserRolePermission(params.data, params.id);
            return response;
        } catch (error) {
            throw error.response;
        }
    },

    async assignMultipleUserPermission({ commit }, { payload, sysModId, roleId }) {
        try {
            const response = await api.assignMultiplePermissions(payload, sysModId, roleId);
            return response;
        } catch (error) {
            throw error.response;
        }
    },

    //system role
    async authUserSystemPermissions({ commit }) {
        // if (state.authUserPerms !== null) {
        //     return state.authUserPerms; // Return cached permissions if available
        //   }
        try {
            const response = await api.authUserSystemPermissions();
            // console.log('authUserSystemPermissions ' + response);
            commit('setAuthUserPerms', response);
            
            return response;
        } catch (error) {
            
            // throw error.response;
        }
    },

    async updateUserSystemPermission({ commit }, params) {
        try {
            const response = await api.updateUserSystemPermission(params.data, params.id);
            return response;
        } catch (error) {
            throw error.response;
        }
    },

    async assignMultipleUserSystemPermission({ commit }, { payload, sysModId, sysRoleId }) {
        try {
            const response = await api.assignMultipleSystemPermissions(payload, sysModId, sysRoleId);
            return response;
        } catch (error) {
            throw error.response;
        }
    },

    async systemModuleDetails({ commit }, id) {
        try {
            const response = await api.systemModuleInfo(id);
            return response;
        } catch (error) {
            throw error.response;
        }
    },

    //Permission headers
    async fetchPermissionHeaders({ commit }) {
        try {
            const response = await api.permissionHeaders();
            commit('setPermHeaders', response.data);
            return response;
        } catch (error) {
            throw error.response;
        }
    },

    async fetchBasicPermissionHeaders({ commit }) {
        try {
            const response = await api.basicPermissionHeaders();
            commit('setBasicPermHeaders', response.data);
            return response;
        } catch (error) {
            throw error.response;
        }
    },
}

const mutations = {

    setPermissions(state, permission) {
        state.permissions = permission
    },

    setAllRoles(state, roles) {
        state.roles = roles
    },

    setRoleUser(state, user) {
        state.roleUsers = user
    },

    setUserNotInRole(state, user) {
        state.userNotInRole = user
    },

    setUsersInRoles(state, data) {
        state.usersInRoles = data
    },

    setPermHeaders(state, perm_headers) {
        state.permHeaders = perm_headers
    },

    setBasicPermHeaders(state, basic_headers) {
        state.basicPermHeaders = basic_headers
    },

    setAuthUserPerms(state, userPerms) {
        state.authUserPerms = userPerms
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
// People form setup
import {
    savePeopleSetupFormRequest,
    fetchPeopleSetupFormsRequest,
    fetchPeopleSetupFormRequest,
    updatePeopleSetupFormRequest,
    deletePeopleSetupFormRequest,
    submitPeopleFormRequest
} from '@/utils/services/people_management/formBuilder/formSetup.js';

// People form section
import {
    savePeopleSectionFormRequest,
    fetchPeopleSectionFormsRequest,
    fetchPeopleSectionFormRequest,
    updatePeopleSectionFormRequest,
    deletePeopleSectionFormRequest,
} from '@/utils/services/people_management/formBuilder/formSection.js';

// People form subsection
import {
    savePeopleSubsectionFormRequest,
    fetchPeopleSubsectionFormsRequest,
    fetchPeopleSubsectionFormIdRequest,
    fetchPeopleSubsectionSectionFormIdRequest,
    updatePeopleSubsectionFormRequest,
    deletePeopleSubsectionFormRequest,
    fetchPeopleSectionFormIdRequest
} from '@/utils/services/people_management/formBuilder/formSubsection.js';

// People form question
import {
    savePeopleQuestionFormRequest,
    fetchPeopleQuestionFormsRequest,
    fetchPeopleQuestionFormRequest,
    updatePeopleQuestionFormRequest,
    deletePeopleQuestionFormRequest
} from '@/utils/services/people_management/formBuilder/formQuestion.js';

// People conditional question
import {
    fetchPeopleConditionalQuestionsRequest
} from '@/utils/services/people_management/settings/conditionalQuestion';


// // People form 
// import {
//     deletePeopleFormRequest
// } from '@/utils/services/people_management/forms';

export const actions = {
    // delete form
    async deletePeopleForm({ commit }, id) {
        try {
            // console.log(id);
            commit('SET_LOADING', true);
            const response = await deletePeopleSetupFormRequest({ id });
            // console.log('Action: ' + response);
            if (response) {
                return response.data;
            }
            throw new Error(response?.data?.message || 'Failed to delete form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // submit form
    async submitPeopleForm({ commit }, id) {
        try {
            // console.log(id);
            commit('SET_LOADING', true);
            const response = await submitPeopleFormRequest({ id });
            // console.log('Action: ' + response);
            if (response) {
                return response.data;
            }
            throw new Error(response?.data?.message || 'Failed to submit people form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // save form
    async savePeopleSetupForm({ commit }, formData) {
        try {
            commit('SET_LOADING', true);
            const response = await savePeopleSetupFormRequest(formData);

            if (response?.data?.success) {
                commit('UPDATE_FORM_SETUP', formData);
            }
            return response;
        } catch (error) {
            commit('SET_ERROR', error?.response?.data?.message);
            throw error.response;

            // throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // fetch people forms
    async fetchPeopleForms({ commit }) {
        try {
            commit('SET_LOADING', true);
            const response = await fetchPeopleSetupFormsRequest();
            if (response?.data?.success) {
                commit('SET_FORMS', response.data.data);
                return response.data.data;
            }
            throw new Error(response?.data?.message || 'Failed to fetch forms');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // fetch form by id
    async fetchFormById({ commit }, id) {
        try {
            commit('SET_LOADING', true);
            const response = await fetchPeopleSetupFormRequest(id);
            if (response?.data?.success) {
                commit('SET_CURRENT_FORM', response.data.data);
                return response.data.data;
            }
            throw new Error(response?.data?.message || 'Failed to fetch form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // update form
    async updatePeopleSetupForm({ commit }, payload) {
        try {
            commit('SET_LOADING', true);
            const response = await updatePeopleSetupFormRequest(payload);

            if (response?.data?.success) {
                commit('UPDATE_FORM_SETUP', payload);
                return response;
            }
            // throw new Error(response?.data?.message || 'Failed to update form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // delete form
    async deletePeopleSetupForm({ commit }, id) {
        try {
            commit('SET_LOADING', true);
            const response = await deletePeopleSetupFormRequest({ id });
            if (response?.data?.success) {
                return response.data;
            }
            throw new Error(response?.data?.message || 'Failed to delete form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // for section operations
    async savePeopleSectionForm({ commit }, formData) {
        try {
            commit('SET_LOADING', true);
            const response = await savePeopleSectionFormRequest(formData);

            if (response?.data?.success) {
                commit('UPDATE_FORM_SETUP', formData);
                commit('SET_CURRENT_FORM', response.data.data);
            }
            return response;
        } catch (error) {
            commit('SET_ERROR', error?.response?.data?.message);
            throw error.response;

            // throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },
    // fetch section forms
    async fetchPeopleSectionForms({ commit }) {
        try {
            commit('SET_LOADING', true);
            const response = await fetchPeopleSectionFormsRequest();
            if (response?.data?.success) {
                commit('SET_FORMS', response.data.data);
                return response.data.data;
            }
            throw new Error(response?.data?.message || 'Failed to fetch forms');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },
    // fetch people section by form_id
    async fetchPeopleSectionFormById({ commit }, { form_id }) {
        try {

            commit('SET_LOADING', true);
            const response = await fetchPeopleSectionFormRequest(form_id);
            // console.log(response);
            if (response?.data?.success) {
                const formData = response.data.data;
                commit('setPeopleSectionFormByIdData', formData);
                return response.data.data;
                // return formData.sections || [];
            }
            throw new Error(response?.data?.message || 'Failed to fetch form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },
    // update people section form
    async updatePeopleSectionForm({ commit }, payload) {
        try {
            commit('SET_LOADING', true);
            const response = await updatePeopleSectionFormRequest(payload);
            if (response?.data?.success) {
                commit('UPDATE_FORM_SETUP', payload);
                return response;
            }
            throw new Error(response?.data?.message || 'Failed to update form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },
    // delete people section form
    async deletePeopleSectionForm({ commit }, id) {
        try {
            commit('SET_LOADING', true);
            const response = await deletePeopleSectionFormRequest({ id });
            if (response?.data?.success) {
                return response.data;
            }
            throw new Error(response?.data?.message || 'Failed to delete form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // for sub sections CRUD operations
    async savePeopleSubsectionForm({ commit }, formData) {
        try {
            commit('SET_LOADING', true);
            const response = await savePeopleSubsectionFormRequest(formData);

            if (response?.data?.success) {
                commit('UPDATE_FORM_SETUP', formData);
            }
            return response;
        } catch (error) {
            commit('SET_ERROR', error?.response?.data?.message);
            throw error.response;

            // throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async fetchPeopleSubsectionForms({ commit }) {
        try {
            commit('SET_LOADING', true);
            const response = await fetchPeopleSubsectionFormsRequest();
            if (response?.data?.success) {
                commit('SET_FORMS', response.data.data);
                return response.data.data;
            }
            throw new Error(response?.data?.message || 'Failed to fetch forms');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async fetchPeopleSubsectionFormById({ commit }, { form_id }) {
        try {
            commit('SET_LOADING', true);
            const response = await fetchPeopleSubsectionFormIdRequest(form_id);
            if (response?.data?.success) {
                return response.data.data;
            }
            throw new Error(response?.data?.message || 'Failed to fetch form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async fetchPeopleSectionFormBySectionId({ commit }, { section_id }) {
        try {
            commit('SET_LOADING', true);
            const response = await fetchPeopleSubsectionSectionFormIdRequest(section_id);
            if (response?.data?.success) {
                return response.data.data;
            }
            throw new Error(response?.data?.message || 'Failed to fetch form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },
    // 
    async updatePeopleSubsectionForm({ commit }, payload) {
        try {
            commit('SET_LOADING', true);
            const response = await updatePeopleSubsectionFormRequest(payload);
            if (response?.data?.success) {
                commit('UPDATE_FORM_SETUP', payload);
                return response;
            }
            throw new Error(response?.data?.message || 'Failed to update form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async deletePeopleSubsectionForm({ commit }, id) {
        try {
            commit('SET_LOADING', true);
            const response = await deletePeopleSubsectionFormRequest({ id });
            if (response?.data?.success) {
                return response.data;
            }
            throw new Error(response?.data?.message || 'Failed to delete form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // for questions CRUD operations
    async savePeopleQuestionForm({ commit }, formData) {
        try {
            commit('SET_LOADING', true);
            const response = await savePeopleQuestionFormRequest(formData);

            if (response?.data?.success) {
                commit('UPDATE_FORM_SETUP', formData);
            }
            return response;
        } catch (error) {
            commit('SET_ERROR', error?.response?.data?.message);
            throw error.response;

            // throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },
    // 
    async fetchPeopleQuestionForms({ commit }) {
        try {
            commit('SET_LOADING', true);
            const response = await fetchPeopleQuestionFormsRequest();
            if (response?.data?.success) {
                commit('SET_FORMS', response.data.data);
                return response.data.data;
            }
            throw new Error(response?.data?.message || 'Failed to fetch forms');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // 
    async fetchPeopleQuestionFormById({ commit }, id) {
        try {
            commit('SET_LOADING', true);
            const response = await fetchPeopleQuestionFormRequest(id);
            if (response?.data?.success) {
                return response.data.data;
            }
            throw new Error(response?.data?.message || 'Failed to fetch form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // 
    async updatePeopleQuestionForm({ commit }, payload) {
        try {
            commit('SET_LOADING', true);
            const response = await updatePeopleQuestionFormRequest(payload);
            if (response?.data?.success) {
                commit('UPDATE_FORM_SETUP', payload);
                return response;
            }
            throw new Error(response?.data?.message || 'Failed to update form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // 
    async deletePeopleQuestionForm({ commit }, id) {
        try {
            commit('SET_LOADING', true);
            const response = await deletePeopleQuestionFormRequest({ id });
            if (response?.data?.success) {
                return response.data;
            }
            throw new Error(response?.data?.message || 'Failed to delete form');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // fetchPeopleConditionalQuestionsRequest
    async fetchPeopleConditionalQuestions({ commit }) {
        try {
            commit('SET_LOADING', true);
            const response = await fetchPeopleConditionalQuestionsRequest();
            if (response?.data?.success) {
                commit('SET_FORMS', response.data.data);
                return response.data.data;
            }
            throw new Error(response?.data?.message || 'Failed to fetch forms');
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // UI State Management
    setCurrentStep({ commit }, step) {
        commit('SET_CURRENT_STEP', step);
    },

    updateDropdownLists({ commit }, data) {
        commit('UPDATE_DROPDOWN_LISTS', data);
    }
};
import { token } from "@/config";
// guest routes

export const middleware = (routes) => {
    return routes.beforeEach((to, from, next) => {
        // Define an array of public pages that do not require authentication
        const publicPages = [
            '/auth/login',
            '/auth/forgot-password',
            '/auth/new-password',
            '/auth/verify',
            '/auth/2fa'
        ];


        // Determine whether the requested page requires authentication
        const authRequired = !publicPages.includes(to.path);

        // Check if the user is logged in by verifying the presence of the user token in localStorage
        const loggedIn = !!localStorage.getItem("user-token");

        // console.log("checking logged in status", loggedIn);

        if (authRequired && !loggedIn) {

            next('/auth/login')
        } else {
            window.document.title = to.meta && to.meta.title ? to.meta.title : 'Total Care Score';
            // Otherwise, allow the user to access the requested page
            next()
        }

    })
}
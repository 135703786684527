export const state = {
    // isLoading: false,
    // error: null,
    // peopleFormResponses: [],
    // currentFormResponse: {
    //     id: null,
    //     responses: {},
    //     currentSubsection: null
    // },
    // submissionInProgress: false
    peopleFormResponses: [],
    peopleFormQuestionResponse: null,
    currentFormResponse: null,
    isLoading: false,
    error: null,
    formSubmissionInProgress: false
};
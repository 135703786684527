import { checkPermissions } from '@/utils/routeService/useCheckPermissions';

export default
[
    {
        path: '/scoring/dashboard',
        name: 'scoring_dashboard',
        component: () => import ('@/views/scoring/dashboard/Dashboard.vue'),
    }, 
    {
        path: '/scoring-settings',
        name: 'ScoringSettings',
        component: () => import('@/views/scoring'),
        meta: { requiresAuth: true }
    },
    {
        path: '/scoring-settings/types',
        name: 'ScoringSettingTypes',
        component: () => import('@/views/scoring/settings/ScoringTypeSettings.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/scoring-settings/types/:id',
        name: 'ScoringSettingTypesFormCompletionScore',
        component: () => import('@/views/scoring/settings/FormCompletionScore.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/scoring-settings/types/:id',
        name: 'ScoringSettingTypesFormTimeLineAdjustment',
        component: () => import('@/views/scoring/settings/FormTimeLineAdjustment.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/scoring-settings/types/:id',
        name: 'ScoringSettingTypesFormSupervisor',
        component: () => import('@/views/scoring/settings/FormSupervisor.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/scoring-settings/lateness-levels',
        name: 'LatenessLevelSetting',
        component: () => import('@/views/scoring/settings/LatenessLevelSetting.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/scoring-settings/types/:id/categories/:category',
        name: 'ScoringSettingTypesFormTimeLineAdjustmentCategory',
        component: () => import('@/views/scoring/settings/ScoreAdjustment.vue'),
        meta: { requiresAuth: true }
    },
]
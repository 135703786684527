// export const mutations = {
//     SET_FORMS(state, forms) {
//         state.peopleFormResponses = forms;
//     },
//     SET_LOADING(state, status) {
//         state.isLoading = status;
//     },
//     SET_ERROR(state, error) {
//         state.error = error;
//     },
//     // New mutations
//     SET_CURRENT_FORM_RESPONSE(state, { id, responses }) {
//         state.currentFormResponse = {
//             ...state.currentFormResponse,
//             id,
//             responses: { ...state.currentFormResponse.responses, ...responses }
//         };
//     },
//     SET_SUBMISSION_STATUS(state, status) {
//         state.submissionInProgress = status;
//     },
//     CLEAR_CURRENT_FORM_RESPONSE(state) {
//         state.currentFormResponse = {
//             id: null,
//             responses: {},
//             currentSubsection: null
//         };
//     }
// };

// mutations
export const mutations = {
    SET_FORMS(state, forms) {
        state.peopleFormResponses = forms;
    },
    SET_CURRENT_FORM(state, form) {
        state.currentFormResponse = form;
    },
    setPeopleFormQuestionResponse(state, form) {
        state.peopleFormQuestionResponse = form;
    },
    SET_LOADING(state, status) {
        state.isLoading = status;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_SUBMISSION_STATUS(state, status) {
        state.formSubmissionInProgress = status;
    },
    UPDATE_FORM_RESPONSES(state, { formId, responses }) {
        const formIndex = state.peopleFormResponses.findIndex(form => form.id === formId);
        if (formIndex !== -1) {
            state.peopleFormResponses[formIndex].responses = responses;
        }
    }
};
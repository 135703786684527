// form module 
import { checkPermissions } from '@/utils/routeService/useCheckPermissions';



export default
    [
        {
            path: 'forms/dashboard',
            name: 'FormDashboard',
            component: () => import('@/views/incidents/IncidentDashboard.vue'),
            meta: {
                requiresAuth: true,
                title: 'Form Dashboard',
                check: 'show_forms',
            },
            beforeEnter: checkPermissions,
        },
        {
            path: '/form-builder',
            name: 'form_builder',
            component: () => import('@/views/formBuilder/AddForm.vue'),
            meta: {
                requiresAuth: true,
                title: 'Add New Form',
            }
        },
        {
            path: '/people-management/form-builder',
            name: 'createPeopleForm',
            component: () => import('@/views/people_management/formBuilder/index.vue'),
            meta: {
                requiresAuth: true,
                title: 'Add New People Management Form',
            }
        },
        {
            path: '/people-management/form-builder/:form_id',
            name: 'editPeopleForm',
            component: () => import('@/views/people_management/formBuilder/update.vue'),
            meta: {
                requiresAuth: true,
                title: 'Update People Management Form',
            }
        },
        {
            path: '/people-management/form-builder/:form_id/view',
            name: 'viewPeopleForm',
            component: () => import('@/views/people_management/formBuilder/view.vue'),
            meta: {
                requiresAuth: true,
                title: 'View People Management Form',
            }
        },
        {
            path: '/sub-form/:form_id/:form_type',
            name: 'add_subform_attached',
            component: () => import('@/views/formBuilder/AddSubForm.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/sub-form/:form_type',
            name: 'add_subform',
            component: () => import('@/views/formBuilder/AddSubForm.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/form-editing/:id',
            name: 'form_editing',
            component: () => import('@/views/formBuilder/EditForm.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/subform-editing/:id',
            name: 'subform_editing',
            component: () => import('@/views/formBuilder/EditSubForm.vue'),
            meta: { requiresAuth: true }
        },

        {
            path: '/dynamic-forms',
            name: 'dynamic_forms',
            component: () => import('@/views/formBuilder/ViewForms.vue'),
            meta: {
                requiresAuth: true,
                check: 'show_forms',
                title: 'Forms',
            },
            beforeEnter: checkPermissions,
        },
        {
            path: '/view-form/:id',
            name: 'view_form',
            meta: { requiresAuth: true },
            component: () => import('@/views/formBuilder/ViewForm.vue'),
        },
        {
            path: 'forms/reviews',
            name: 'form_reviews',
            component: () => import('@/views/formBuilder/AddForm.vue'),
            meta: { requiresAuth: true },
        },
        {
            path: 'forms/submissions',
            name: 'form_submissions',
            component: () => import('@/views/formBuilder/ViewSubmissions.vue'),
            meta: { requiresAuth: true },
        },
        {
            path: '/form-settings',
            name: 'form_settings',
            component: () =>
                import('@/views/formBuilder/FormSettings.vue'),
            meta: {
                requiresAuth: true,
                check: 'show_settings',
            },
            beforeEnter: checkPermissions,
        },
        {
            path: '/form-settings/scoring-categories',
            name: 'ScoringCategories',
            component: () => import('@/views/formBuilder/components/settings/ScoringCategories.vue'),
            meta: { requiresAuth: true },
        },
        {
            path: '/form-settings/task-types',
            name: 'TaskTypes',
            component: () => import('@/views/formBuilder/components/settings/TaskTypes.vue'),
            meta: { requiresAuth: true },
        },
        {
            path: '/form-settings/task-groups',
            name: 'TaskGroups',
            component: () => import('@/views/formBuilder/components/settings/FormGroups.vue'),
            meta: { requiresAuth: true },
        },
        {
            path: '/form-settings/data-categories',
            name: 'DataCategories',
            component: () => import('@/views/formBuilder/components/settings/DataCategories.vue'),
            meta: { requiresAuth: true },
        },
        {
            path: '/form-settings/task-categories',
            name: 'TaskCategories',
            component: () => import('@/views/formBuilder/components/settings/TaskCategories.vue'),
            meta: { requiresAuth: true },
        },
        {
            path: '/form-settings/task-modules',
            name: 'TaskModules',
            component: () => import('@/views/formBuilder/components/settings/TaskModules.vue'),
            meta: { requiresAuth: true },
        },












    ];
import axios from "axios";
import config from "@/config";
const { baseAPI, Header } = config;

// urls endpoints for Peoples form
const requestURI = 'people_management/form-builder/subsections';
// const requestQuestionsUrl ='';

// save People set-up form
export const savePeopleSubsectionFormRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        throw error.response || error;
    }
}

// 
export const fetchPeopleSubsectionFormsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        throw error.response || error;
    }
}

// 
export const fetchPeopleSubsectionFormRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        throw error.response || error;
    }
}

// 
export const fetchPeopleSubsectionSectionFormIdRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}/section`, Header);
    } catch (error) {
        throw error.response || error;
    }
}


// 
export const updatePeopleSubsectionFormRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/update`, payload, Header);
    } catch (error) {
        throw error.response || error;
    }
}

// 
export const deletePeopleSubsectionFormRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/delete`, payload, Header);
    } catch (error) {
        throw error.response || error;
    }

}


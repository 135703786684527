import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

export const getTaskTemplatesReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/templates`, payload, Header);
    } catch (error) {
        return error
    }
}
export const getTaskForReview = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/get-task-review`, payload, Header);
    } catch (error) {
        return error
    }
}
export const storeTaskReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks`, payload, Header);
    } catch (error) {
        return error
    }
}
export const updateTaskReq = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/tasks/${id}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const getTaskDetailReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/${payload}`, Header);
    } catch (error) {
        return error
    }
}

export const reassignMultiTaskReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/resign-multiple`, payload, Header);
    } catch (error) {
        return error
    }
}

export const getTaskModulesReq = async () => {
    try {
        return await axios.get(`${baseAPI}/tasks/modules`, Header);
    } catch (error) {
        return error
    }
}

export const getTaskOverviewReq = async () => {
    try {
        return await axios.post(`${baseAPI}/tasks/overview`, Header);
    } catch (error) {
        return error
    }
}

export const getTaskCategoriesReq = async () => {
    try {
        return await axios.get(`${baseAPI}/tasks/categories`, payload, Header);
    } catch (error) {
        return error
    }
}

export const getFieldTemplatesReq = async () => {
    try {
        return await axios.get(`${baseAPI}/tasks/field-templates`, payload, Header);
    } catch (error) {
        return error
    }
}

export const getTaskTemplateDetailsReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/details/` + payload, Header);
    } catch (error) {
        return error
    }
}

export const storeTaskFieldReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/template/fields/` + payload, Header);
    } catch (error) {
        return error
    }
}

export const getTemplateFieldDetailsReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/fields/details`, payload, Header);
    } catch (error) {
        return error
    }
}

export const fetchTemplateFieldsReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/fields/details/` + payload, Header);
    } catch (error) {
        return error
    }
}

export const getTasksReq = async () => {
    try {
        return await axios.get(`${baseAPI}/tasks`, Header);
    } catch (error) {
        return false
    }
}

export const getTasksClientServiceReq = async (external_id) => {
    try {
        return await axios.get(`${baseAPI}/tasks/client-service/${external_id}`, Header);
    } catch (error) {
        return false
    }
}

export const getGroupedTasks = async (params) => {
    try {
        return await axios.get(`${baseAPI}/tasks/grouped-tasks`, Header);
    } catch (error) {
        return false
    }
}


export const getSingleTaskReq = async (params) => {
    try {
        return await axios.get(`${baseAPI}/tasks/selected-task/${params}`, Header);
    } catch (error) {
        return false
    }
}

export const getSampleTaskInfo = async (id) => {
    try {
        return await axios.get(`${baseAPI}/get-sample/${id}/info`, Header);
    } catch (error) {
        return false
    }
}

export const getUserTaskTotalDataReq = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user-task-count/${id}`, Header);
    } catch (error) {
        return false
    }
}

export const getTasksUserReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/tasksuser`, payload, Header);
    } catch (error) {
        return error
    }
}

export const getTasksTeam = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/tasksteam`, payload, Header);
    } catch (error) {
        return error
    }
}
export const storeUserTaskReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateTaskFormSubmissionReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/${payload['task_id']}/update-form-submission`, payload, Header);
    } catch (error) {
        return error
    }
}

export const saveTaskFormReviewReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/${payload['task_id']}/save-form-review`, payload, Header);
    } catch (error) {
        return error
    }
}

export const reassignTaskReq = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/tasks/${id}/reassign`, payload, Header);

    } catch (error) {
        return error
    }
}

export const reassignTaskToOriginalReq = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/tasks/${id}/reassign-original`, payload, Header);

    } catch (error) {
        return error
    }
}

export const escalateTaskReq = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/tasks/${id}/escalate`, payload, Header);
    } catch (error) {
        return error
    }
}

export const getTaskOriginalReq = async (id) => {
    try {
        return await axios.get(`${baseAPI}/task-original-user/${id}`, Header).then((data) => data.data);
    } catch (error) {
        return error
    }
}

export const TaskReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/store-tasks`, payload, Header);
    } catch (error) {
        return error
    }
}

export const storeTaskEscalationReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/escalate-task`, payload, Header);
    } catch (error) {
        return error
    }
}

export const storeTaskReviewReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/store-task-review`, payload, Header);
    } catch (error) {
        return error
    }
}

export const storeTaskReassignReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/reassign`, payload, Header);
    } catch (error) {
        return error
    }
}

export const getTaskSelectedReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/taskselected/` + payload, Header);
    } catch (error) {
        return error
    }
}

export const getTaskFormDataReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/form/` + payload, Header);
    } catch (error) {
        return error
    }
}

export const getTaskItemsReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/taskitemsopen/${payload}`, Header);
    } catch (error) {
        return error
    }
}

export const getTaskItemsCompletedReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/taskitemscomplete/${payload}`, Header);
    } catch (error) {
        return error
    }
}

export const getTaskItemsOverdueReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/taskitemsoverdue/${payload}`, Header);
    } catch (error) {
        return error
    }
}

export const getTaskSubItemsReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/subtaskitemselected/${payload}`, Header);
    } catch (error) {
        return error
    }
}

export const getTaskSubItemsOpenReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/subtaskitemsopen/${payload}`, Header);
    } catch (error) {
        return error
    }
}

export const getTaskRelatedSubItemsReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/tasks/subtaskitemsrelated/${payload}`, Header);
    } catch (error) {
        return error
    }
}

export const getTaskSubItemsReviewedReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/subtaskitems-escalated/${payload}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const getTaskSubItemsEscalatedReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/subtaskitems-escalated/${payload}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const draftSubTaskReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/draft`, payload, Header);
    } catch (error) {
        return error
    }
}

export const submitSubTaskReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/submit-task`, payload, Header);
    } catch (error) {
        return error
    }
}

export const deleteTaskReq = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/tasks/${id}/delete`, payload, Header);
    } catch (error) {
        return error
    }
}

export const deleteSupervisorReq = async (id) => {
    try {
        console.log("id")
        console.log(id)
        return await axios.delete(`${baseAPI}/supervisor-review/${id}/delete`, Header);
    } catch (error) {
        return error
    }
}

export const fetchAllTaskPrioritiesReq = async () => {
    try {
        return await axios.get(`${baseAPI}/task-priorities`, Header);
    } catch (error) {
        return error
    }
}

export const getTaskPriorityDetailsByIdReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/task-priorities/` + payload, Header);
    } catch (error) {
        return error
    }
}

export const saveTaskPriorityReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/task-priorities`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateTaskPriorityReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/task-priorities/` + payload['id'] + '/update', payload, Header);
    } catch (error) {
        return error
    }
}

export const deleteTaskPriorityReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/task-priorities/` + payload + '/delete', payload, Header);
    } catch (error) {
        return error
    }
}

export const fetchAllTaskFrequenciesReq = async () => {
    try {
        return await axios.get(`${baseAPI}/task-frequencies`, Header);
    } catch (error) {
        return error
    }
}

export const getTaskFrequencyDetailsByIdReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/task-frequencies/${payload}`, Header);
    } catch (error) {
        return error
    }
}

export const saveTaskFrequencyReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/task-frequencies`, payload, Header);
    } catch (error) {
        return error
    }
}

// export const updateTaskFrequencyReq = async (payload) => {
//     try {
//         return await axios.post(`${baseAPI}/task-frequencies/` + payload['id'] + '/update', payload, Header);
//     } catch (error) {
//         return error
//     }
// }


export const fetchAllClientsReq = async () => {
    try {
        return await axios.get(`${baseAPI}/clients`, Header);
    } catch (error) {
        return error
    }
}
export const fetchUserRolesReq = async () => {
    try {
        return await axios.post(`${baseAPI}/user/roles`, Header);
    } catch (error) {
        return error
    }
}
export const fetchFormsReq = async () => {
    try {
        return await axios.post(`${baseAPI}/form/fetch-forms`, Header);
    } catch (error) {
        return error
    }
}
export const fetchUsersFromRolesReq = async () => {
    try {
        return await axios.post(`${baseAPI}/clients`, Header);
    } catch (error) {
        return error
    }
}
export const fetchTaskCategoriesReq = async () => {
    try {
        return await axios.post(`${baseAPI}/clients`, Header);
    } catch (error) {
        return error
    }
}

export const getTaskTypesReq = async () => {
    try {
        return await axios.get(`${baseAPI}/task-type`, Header);
    } catch (error) {
        return error
    }
}

export const getFormReq = async () => {
    try {
        return await axios.get(`${baseAPI}/form/fetch-forms`, Header);
    } catch (error) {
        return error
    }
}

// 
export const changeStatus = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/${payload?.id}/change-status`, payload, Header);
    } catch (error) {
        return error
    }
}
export const reviewFeedbackOptionsReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/review-feedbacks`, Header);
    } catch (error) {
        return error
    }
}


// Task Review
export const rateReviewReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/rate-review`, payload, Header);
    } catch (error) {
        return error
    }
}

export const getRateReviewReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/rate-review`, Header);
    } catch (error) {
        return error
    }
}


// feedback completion
export const postFeedbackForCompletion = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/tasks/${id}/feedback-for-completion`, payload, Header);
    } catch (error) {
        return error
    }
}
export const getFeedbackReq = async (payload, id) => {
    try {
        return await axios.get(`${baseAPI}/tasks/${id}/task-feedback`, Header);
    } catch (error) {
        return error
    }
}
export const getInvestigationselectedTaskReq = async (id, form) => {
    try {
        return await axios.get(`${baseAPI}/tasks/${id}/task-investigation`, Header);
    } catch (error) {
        return error
    }
}

export const submitReviewReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/store-task-review`, payload, Header);
    } catch (error) {
        return error
    }
}
export const storeTaskInvestigationReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/tasks/store-task-investigation`, payload, Header);
    } catch (error) {
        return error
    }
}

export const getTaskReviewReq = async (id) => {
    try {
        return await axios.get(`${baseAPI}/tasks/${id}/get-task-review`, Header);
    } catch (error) {
        return error
    }
}

export const getSamplingTask = async (id) => {
    try {
        return await axios.get(`${baseAPI}/tasks/${id}/get-task-review`, Header);
    } catch (error) {
        return error
    }
}


// update form response 
export const updateFormReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/form/update-form-responses`, payload, Header);
    } catch (error) {
        return error
    }
}
export const duplicateTaskReq = async (id) => {
    try {
        return await axios.post(`${baseAPI}/tasks/${id}/duplicate`, null, Header);
    } catch (error) {
        return error
    }
}
// export const taskForReviewSupervisor = async () => {
//     try {
//         return await axios.get(`${baseAPI}/supervisor-review`, Header);
//     } catch (error) {
//         return error
//     }
// }
export const saveSupervisorSampleReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/post-supervisor-review`, payload, Header);
    } catch (error) {
        return error
    }
}
export const getSupervisorSampleTaskReq = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/get-supervisor-review?type=${payload.type}&id=${payload.id}`, Header);
    } catch (error) {
        return error
    }
}

export const storeTaskReview = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/tasks/save-task-review/${id}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const getTaskDashboardData = async (params) => {
    try {
        const { type, period, startDate, endDate } = params;
        let url = `${baseAPI}/tasks/data/dashboard?type=${type}&period=${period}`;

        if (startDate) {
            url += `&startDate=${startDate}`;
        }

        if (endDate) {
            url += `&endDate=${endDate}`;
        }

        const res = await axios.get(url, Header);

        return res;
    } catch (error) {
        console.error('Error getting task dashboard details:', error);
        return error;
    }
}

export const mailCSV = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/send-mail`, payload, Header);
    } catch (error) {
        return error
    }
}
export const getOnlyFormsFeq = async () => {
    try {
        return await axios.get(`${baseAPI}/form/get-only-forms`, Header);
    } catch (error) {
        return error
    }
}
export const getFollowUpFormsFeq = async () => {
    try {
        return await axios.get(`${baseAPI}/form/get-followup-forms`, Header);
    } catch (error) {
        return error
    }
}
export const getFeedbackFormsFeq = async () => {
    try {
        return await axios.get(`${baseAPI}/form/get-feedback-forms`, Header);
    } catch (error) {
        return error
    }
}

export const dropdownReq = async () => {
    try {
        return await axios.get(`${baseAPI}/task-dropdowns`, Header);
    } catch (error) {
        return error
    }
}

   // fetch task status
    export const getTaskStatusesReq  = async () => {
             try {
            return await axios.get(`${baseAPI}/tasks/statuses`, Header);
        } catch (error) {
            return error
        }
}
    // taskForReviewSupervisor
export const getTaskForReviewSupervisor = async () => {
    try {
        return await axios.get(`${baseAPI}/supervisor-review`, Header);
    } catch (error) {
        return error
    }
}

// 
export const getSampleTasks = async (id) => {
    try {
        return await axios.get(`${baseAPI}/tasks/${id}/sample`, Header);
    } catch (error) {
        return error
    }
}

import { state } from "./settingsState.js"
import { actions } from "./settingsActions.js"
import { getters } from "./settingsGetters.js"
import { mutations } from "./settingsMutation.js"

export default {
    state,
    actions,
    getters,
    mutations
}
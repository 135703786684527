import { createStore } from 'vuex'
import auth from './auth'
import tasks from './tasks'
import emails from './emails'
import companies from './companies'
// incidents module
import incidents from './incidents'
import complaints from './complaints'
import casecoordinators from './case-coordinators'
import intakecoordinators from './intake-coordinators'
import homecareworkers from './homecareworkers'
import clients from './clients'
import forms from './forms'
import permission from './permission'
import coordinators from './coordinators'
import nurses from './nurses'
import nurse_assessment from './nurse_assessment'
import users from './users'
import systemmodules from './systemmodules'
import usermodules from './usermodules'
import notifications from './notifications'
import systemactivities from './systemactivities'
import roles from './roles'
import tenant from './multitenant'
import settings from './settings'
import reports from './reports'

import schedule_management from './schedule_management'
import people_management from './people_management'

export default createStore({
    modules: {
        auth,
        incidents,
        complaints,
        tasks,
        emails,
        nurses,
        companies,
        forms,
        casecoordinators,
        intakecoordinators,
        homecareworkers,
        clients,
        permission,
        coordinators,
        users,
        systemmodules,
        usermodules,
        nurse_assessment,
        notifications,
        systemactivities,
        roles,
        tenant,
        schedule_management,
        settings,
        reports,
        people_management,
    }
})
import { state } from './state';
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';


export default {
    namespaced: false,
    state: {
        forms: [],
        currentForm: null,
        isLoading: false,
        error: null,
        dropdownLists: {
            form_type_id: '',
            program_category_id: [],
            program_type_id: [],
            scoring: []
        }
    },
    mutations,
    actions,
    getters
}
import axios from "axios";
import config from "@/config";
const { baseAPI, Header, PhotoHeader, AuthHeader } = config;


export const getAuthRolePermissionInfo = async() => {
    try {

        //this is called twice, needs review
        const result = await axios.get(`${baseAPI}/user/roles-permission-check`, Header);

     
        return result;
    } catch (error) {
        // console.error('Error getting auth user roles info:', error);
        // throw error;
        // return null;
        localStorage.removeItem('user-token');
        return error;
    }
};

export const getRoleDetails = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/role/${id}`, Header);
    } catch (error) {
        // console.error('Error finding user role details:', error);
        // throw error;
    }
};
import axios from "axios";
import config from "@/config";
const { baseAPI, Header } = config;

const requestURI = 'people_management/form-response';

export const savePeopleFormResponseRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        // console.error('Services: ' + error?.response?.data?.data);
        throw error.response || error;
    }
}

export const fetchPeopleFormResponsesRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        throw error.response || error;
    }
}

export const fetchPeopleFormResponseRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        throw error.response || error;
    }
}

export const updatePeopleFormResponseRequest = async (payload) => {
    try {
        // Changed to PUT method
        return await axios.post(`${baseAPI}/${requestURI}/${payload.id}/update`, payload, Header);
    } catch (error) {
        throw error.response || error;
    }
}

export const deletePeopleFormResponseRequest = async (payload) => {
    try {
        // Changed to DELETE method
        return await axios.delete(`${baseAPI}/${requestURI}/${payload.id}/delete`, Header);
    } catch (error) {
        throw error.response || error;
    }
}

// New method for form submission
export const submitFormResponseRequest = async (id) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/submit`, {}, Header);
    } catch (error) {
        throw error.response || error;
    }
}

// New method to get responses for a specific form
export const getFormResponsesRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}/responses`, Header);
    } catch (error) {
        throw error.response || error;
    }
}
export const getters = {
	activeFormsList:state => state.activeFormsList,
	draftFormsList:state => state.draftFormsList,
	fillableForm:state => state.fillableForm,
	fieldsInventoryList:state => state.fieldsInventoryList,
	formFunctionsList:state => state.formFunctionsList,
	formTypesList:state => state.formTypesList,
	formsList:state => state.formsList,
	submissionsList:state => state.submissionsList,
	dataCategoriesList:state => state.dataCategoriesList,
	formGroupsList:state => state.formGroupsList,
	formHeadersList:state => state.formHeadersList,
	scoringCategoriesList:state => state.scoringCategoriesList,
	taskCategoriesList:state => state.taskCategoriesList,
	formSectionList:state => state.formSectionList,
	sectionList:state => state.sectionList,
	formReviewsList:state => state.formReviewsList,
	taskModulesList:state => state.taskModulesList,
	userGroupsList:state => state.userGroupsList,
	formTaskTypesList:state => state.formTaskTypesList,
	questionTypesList:state => state.questionTypesList,
	parentQuestionsList:state => state.parentQuestionsList,
	parentOptionsList:state => state.parentOptionsList,
	feedbackFormList:state => state.feedbackFormList,
	followUpFormList:state => state.followUpFormList,
	section: (state) => state.section,
	getForms: (state) => state.forms,
    listFormDropdowns: (state) => state.form_dropdowns,

	getFormSections(state) {return state.formSections; },
	getFormSubSections(state) {return state.formSubSections; },
	getFormQuestions(state) {return state.formQuestions; },
	getFormQuestionOptions(state) {return state.formQuestionOptions; },


	getSubsections:state => sectionId => {
		return state.subsections.filter(subsection => subsection.sectionId === sectionId);
	  },
	  getQuestions: state => subsectionId => {
		return state.questions.filter(question => question.subsectionId === subsectionId);
	  }

}
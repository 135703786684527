

export default
    [
        {
            path: '/billings-settings',
            name: 'billingsSettings',
            component: () => import('@/views/billings'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/payor-type',
            name: 'billingsPayorTypeSettings',
            component: () => import('@/views/billings/settings/PayorType.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/payor',
            name: 'billingsPayorSettings',
            component: () => import('@/views/billings/settings/Payor.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/complexity-category',
            name: 'billingsComplexityCategorySettings',
            component: () => import('@/views/billings/settings/ComplexityCategory.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/complexity-type',
            name: 'billingsComplexityTypeSettings',
            component: () => import('@/views/billings/settings/ComplexityType.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/service-period',
            name: 'billingsServicePeriodSettings',
            component: () => import('@/views/billings/settings/ServicePeriod.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/variable',
            name: 'billingsVariableSettings',
            component: () => import('@/views/billings/settings/Variable.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/discount-category',
            name: 'billingsDiscountCategorySettings',
            component: () => import('@/views/billings/settings/DiscountCategory.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/service-code-type',
            name: 'billingsServiceCodeTypeSettings',
            component: () => import('@/views/billings/settings/ServiceCodeType.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/service-code-category',
            name: 'billingsServiceCodeCategorySettings',
            component: () => import('@/views/billings/settings/ServiceCodeCategory.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/service-category',
            name: 'billingsServiceCategorySettings',
            component: () => import('@/views/billings/settings/ServiceCategory.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/unit-of-measure',
            name: 'billingsUnitOfMeasureSettings',
            component: () => import('@/views/billings/settings/UnitOfMeasure.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/provider-type',
            name: 'billingsProviderTypeSettings',
            component: () => import('@/views/billings/settings/ProviderType.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/place-of-service',
            name: 'billingsPlaceOfServiceSettings',
            component: () => import('@/views/billings/settings/PlaceOfService.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/modifier',
            name: 'billingsModifierSettings',
            component: () => import('@/views/billings/settings/Modifier.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/revenue-code',
            name: 'billingsRevenueCodeSettings',
            component: () => import('@/views/billings/settings/RevenueCode.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/required-documentation',
            name: 'billingsRequiredDocumentationSettings',
            component: () => import('@/views/billings/settings/RequiredDocumentation.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/taxonomy-code',
            name: 'billingsTaxonomyCodeSettings',
            component: () => import('@/views/billings/settings/TaxonomyCode.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/resolve-collection-action',
            name: 'billingsResolveCollectionActionSettings',
            component: () => import('@/views/billings/settings/ResolveCollectionAction.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/payment-methods',
            name: 'billingsPaymentMethodSettings',
            component: () => import('@/views/billings/settings/PaymentMethod.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/billing-status',
            name: 'billingsBillingStatusSettings',
            component: () => import('@/views/billings/settings/BillingStatus.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/billable-status',
            name: 'billingsBillableStatusSettings',
            component: () => import('@/views/billings/settings/BillableStatus.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/service-review-status',
            name: 'billingsServiceReviewStatusSettings',
            component: () => import('@/views/billings/settings/ServiceReviewStatus.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/service-review-status-reason',
            name: 'billingsServiceReviewStatusReasonSettings',
            component: () => import('@/views/billings/settings/ServiceReviewStatusReason.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/collection-followup-action',
            name: 'billingsCollectionFollowUpActionSettings',
            component: () => import('@/views/billings/settings/CollectionFollowUpAction.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/collection-status',
            name: 'billingsCollectionStatusSettings',
            component: () => import('@/views/billings/settings/CollectionStatus.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/ar-status',
            name: 'billingsArStatusSettings',
            component: () => import('@/views/billings/settings/ArStatus.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/billing-type',
            name: 'billingsBillingTypeSettings',
            component: () => import('@/views/billings/settings/billing-types'),
            // component: () => import('@/views/billings/settings/BillingType.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/billings-settings/billing-type/:billing_type_id/details',
            name: 'billingsBillingTypeSettingsDetail',
            component: () => import('@/views/billings/settings/billing-types/Details.vue'),
            // component: () => import('@/views/billings/settings/BillingType.vue'),
            meta: { requiresAuth: true }
        },

        {
            path: '/billings-dashboard',
            name: 'BillingsDashboard',
            component: () => import('@/views/billings/dashboard/view/dashboard.vue'),
            meta: { requiresAuth: true, }
        },

    ]
import {
    fetchAllTaskFrequenciesReq,
    fetchAllTaskPrioritiesReq,
    fetchTemplateFieldsReq,
    getFieldTemplatesReq,
    getTaskCategoriesReq,
    getTaskStatusesReq,
    getTaskDetailReq,
    getTaskFormDataReq,
    getTaskFrequencyDetailsByIdReq,
    getTaskItemsCompletedReq,
    getTaskItemsOverdueReq,
    getTaskItemsReq,
    getTaskModulesReq,
    getTaskOverviewReq,
    getTaskPriorityDetailsByIdReq,
    getTaskRelatedSubItemsReq,
    getTaskSelectedReq,
    getTaskSubItemsOpenReq,
    getTaskSubItemsReq,
    getTaskTemplateDetailsReq,
    getTaskTemplatesReq,
    getTasksReq,
    getTasksClientServiceReq,
    getGroupedTasks,
    getTasksUserReq,
    getTaskForReviewSupervisor,
    getTaskDashboardData,
    deleteSupervisorReq,
    getSampleTaskInfo,
    getUserTaskTotalDataReq
  } from "@/utils/services/tasks";

import { getHappinessDashboardDataReq, getValueBaseDashboardDataReq, getMetricDashboardDataReq } from "@/utils/services/tasks/metricsDashboardData";

export const actions = {

    async fetchTasks({
        commit
    }, payload) {
        const res = await getTasksReq(payload);

        if (res && res.data) {
            commit('setTask', res.data.data);
        } else {
            // Handle the case when the response is not as expected
            console.error('Invalid response from getTasksReq:', res);
        }
    },

    async fetchTasksClientService({
        commit
    }, payload) {
        const res = await getTasksClientServiceReq(payload);

        if (res && res.data) {
            commit('setTask', res.data.data);
        } else {
            // Handle the case when the response is not as expected
            console.error('Invalid response from getTasksClientServiceReq:', res);
        }
    },


    async fetchGroupedTasks({
        commit
    }, payload) {
        const res = await getGroupedTasks(payload);

        if (res && res.data) {
            commit('setTask', res.data.data);
        } else {
            // Handle the case when the response is not as expected
            console.error('Invalid response from getGroupedTasks:', res);
        }
    },

    

    async fetchTasksUser({
        commit
    }, payload) {
        const res = await getTasksUserReq(payload);
        commit('setTaskUser', res.data)
    },

    async fetchSelectedUsers({
        commit
    }, payload) {
        const res = await getTasksUserReq(payload);
        commit('setTaskUser', res.data)
    },

    async fetchTaskTemplate({
        commit
    }, payload) {
        const res = await getTaskTemplatesReq(payload);
        commit('setTaskTemplate', res.data)
    },

    async fetchTaskDetails(payload) {
        const res = await getTaskTemplateDetailsReq(payload);
    },

    async fetchselectedTask({
        commit
    }, payload) {
        const res = await getTaskSelectedReq(payload);
        commit('setTaskSelected', res.data)
    },

    async fetchTaskById({
        commit
    }, payload) {
        const res = await getTaskDetailReq(payload);
        commit('setTaskDetails', res.data.data)
    },

    async fetchTaskItems({
        commit
    }, payload) {
        const res = await getTaskItemsReq(payload);
        commit('setTaskItems', res.data)
    },

    async fetchTaskItemsComplete({
        commit
    }, payload) {
        const res = await getTaskItemsCompletedReq(payload);
        commit('setTaskItemsComplete', res.data)
    },

    async fetchTaskItemsOverdue({
        commit
    }, payload) {
        const res = await getTaskItemsOverdueReq(payload);
        commit('setTaskItemsOverdue', res.data)
    },

    async fetchTaskSubItems({
        commit
    }, payload) {
        const res = await getTaskSubItemsReq(payload);
        commit('setTaskSubItems', res.data)
    },

    async getTaskDashboardData({
        commit
    }, params) {
        const res = await getTaskDashboardData(params);
        if (res && res.data) {
            commit('setTaskDashboardData', res.data);
        } else {
            // console.log(" is shakey", res);
            //   console.log("getTaskDashboardData returned null or undefined");
            commit('setTaskDashboardData', res);
            // commit('setTaskDashboardData', null);
            // console.log("getTaskDashboardData2", res);
        }
    },
    async getHappinessDashboardData({
        commit
    }, params) {
        const res = await getHappinessDashboardDataReq(params);
        if (res && res.data) {
            commit('setHappinessDashboardData', res.data);
        } else {
            commit('setHappinessDashboardData', res);
        }
    },

        async getUserTaskTotalData({
        commit
    }, params) {
        const res = await getUserTaskTotalDataReq(params);
        if (res && res.data) {
            commit('setUserTaskTotalData', res.data);
        } else {
            commit('setUserTaskTotalData', res);
        }
        return res.data;
    },
    
    async getValueBaseDashboardData({
        commit
    }, params) {
        const res = await getValueBaseDashboardDataReq(params);
        if (res && res.data) {
            commit('setValueBaseDashboardData', res.data);
        } else {
            commit('setValueBaseDashboardData', res);
        }
    },

    async getMetricDashboardData({
        commit
    }, params) {
        const res = await getMetricDashboardDataReq(params);
        if (res && res.data) {
            commit('setMetricDashboardData', res.data);
        } else {
            commit('setMetricDashboardData', res);
        }
    },

    async fetchTaskFormData({
        commit
    }, payload) {
        const res = await getTaskFormDataReq(payload);
        commit('setTaskFormData', res.data)
    },

    async fetchTaskSubItemsOpen({
        commit
    }, payload) {
        const res = await getTaskSubItemsOpenReq(payload);
        commit('setTaskSubItemsOpen', res.data)
    },

    async fetchTaskRelatedSubItems({
        commit
    }, payload) {
        const res = await getTaskRelatedSubItemsReq(payload);
        commit('setTaskRelatedSubItems', res.data)
    },


    async fetchTaskModules({
        commit
    }) {
        const res = await getTaskModulesReq(payload);
        commit('setTaskModule', res.data)
    },

    async fetchTaskOverview({
        commit
    }) {
        const res = await getTaskOverviewReq(payload);
        commit('setTaskOverview', res.data)
    },

    async fetchTaskCategories({
        commit
    }) {
        const res = await getTaskCategoriesReq();
        commit('setTaskCategories', res.data)
    },

        // 
    async fetchTaskStatuses({
        commit
    }) {
        // console.log('daniel');
        const res = await getTaskStatusesReq();
        commit('setTaskStatuses', res.data)

    },

    async fetchTaskFieldTemplate({
        commit
    }) {
        const res = await getFieldTemplatesReq();
        commit('setFieldTemplate', res.data)
    },

    async fetchTaskTemplateDetails({
        commit
    }, payload) {
        const res = await getTasks(payload);
        commit('setTaskTemplateDetails', res.data)
    },


    async fetchTaskTemplateFields({
        commit
    }, payload) {
        const res = await fetchTemplateFieldsReq(payload);
        commit('setTaskTemplateField', res.data)
    },

    async fetchAllTaskPriorities({
        commit
    }, payload) {
        const res = await fetchAllTaskPrioritiesReq(payload);
        commit('setAllTaskPriorities', res.data?.data)

    },

    async fetchTaskPriorityDetails({
        commit
    }, payload) {
        const res = await getTaskPriorityDetailsByIdReq(payload);
        commit('setTaskPriorityDetails', res.data?.data)

    },

    async fetchAllTaskFrequencies({
        commit
    }, payload) {
        const res = await fetchAllTaskFrequenciesReq(payload);
        commit('setAllTaskFrequencies', res.data?.data)

    },

    async fetchTaskFrequencyDetails({
        commit
    }, payload) {
        const res = await getTaskFrequencyDetailsByIdReq(payload);
        commit('setTaskFrequencyDetails', res.data?.data)

    },

    async deleteTaskSupervisorReview({
        commit
    }, payload) {
        console.log(payload)
        const res = await deleteSupervisorReq(payload);
        commit('setTaskFrequencyDetails', res.data?.data)

    },

    async fetchTaskReviewSupervisor({
        commit
    }) {
        const res = await getTaskForReviewSupervisor();
        commit('setTaskForReviewSupervisor', res.data.data)
    },
    
    async fetchSampleTaskInfo({
        commit
    }, payload) {
                console.log("respect")
        const res = await getSampleTaskInfo(payload);
        console.log(res.data.data)
        commit('setSampleTask', res.data.data)
    },
    

    setVisibilityState({
        commit
    }, payload) {
        commit('setVisibilityState', payload)
    },
    updateFilter({
        commit
    }, payload) {
        commit('setFilter', payload)
    },




}
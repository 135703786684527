import axios from "axios";
import config from "@/config";
const { baseAPI, Header, AuthHeader } = config;


export const updateUserPermission = async(params, id) => {
    try {
        return await axios.post(`${baseAPI}/permission/update-role/` + id, params, Header);
    } catch (error) {
        console.error('Error updating user permission:', error);
        throw error;
    }
};

export const getPermissions = async() => {
    try {
        return await axios.get(`${baseAPI}/user/permissions`, Header);
    } catch (error) {
        console.error('Error getting permissions:', error);
        throw error;
    }
};


export const storeRole = async(params) => {
    try {
        return await axios.post(`${baseAPI}/permission/role`, params, Header);
    } catch (error) {
        console.error('Error storing role:', error);
        throw error;
    }
};

export const updateUserRole = async(params) => {
    try {
        return await axios.post(`${baseAPI}/permission/role/${params.id}`, params.params, Header);
    } catch (error) {
        console.error('Error updating user role:', error);
        throw error;
    }
};

export const getRoleUser = async(id) => {
    try {
        return await axios.get(`${baseAPI}/permission/users/${id}`, Header);
    } catch (error) {
        console.error('Error getting role users:', error);
        throw error;
    }
};

export const getUsersInRoles = async(params) => {
    try {
        return await axios.get(`${baseAPI}/permission/selected`, {params, ...Header});
    } catch (error) {
        console.error('Error getting users in roles:', error);
        throw error;
    }
};


export const getRoleUserTask = async(params) => {
    try {
        return await axios.get(`${baseAPI}/permission/selected-task`, { params }, Header);
    } catch (error) {
        console.error('Error getting role user tasks:', error);
        throw error;
    }
};


export const getRoleUserNotIn = async(id, search = null) => {
    try {
        const params = {
            search: search,
        };

        //console.log(id);

        return await axios.get(`${baseAPI}/permission/userNotIn/${id}`, {
            params: params,
            ...Header, // Spread the Header object to include the headers in the request
        });
    } catch (error) {
        console.error('Error getting role user not in:', error);
        throw error;
    }
};


export const editUserRole = async(params) => {
    try {
        return await axios.post(`${baseAPI}/permission/change`, params, Header);
    } catch (error) {
        console.error('Error editing user role:', error);
        throw error;
    }
};

export const getAbilities = async() => {
    try {
        return await axios.get(`${baseAPI}/abilities`, Header);
    } catch (error) {
        console.error('Error getting abilities:', error);
        throw error;
    }
};

export const getAllRoles = async() => {
    try {
        return await axios.get(`${baseAPI}/permission/roles`, Header);
    } catch (error) {
        console.error('Error getting all roles:', error);
        throw error;
    }
};

//user permissions for sidebar

export const updateUserRolePermission = async(params, id) => {
    try {
        return await axios.post(`${baseAPI}/permission/assign-permission/` + id, params, Header);
    } catch (error) {
        console.error('Error updating user role permission:', error);
        throw error;
    }
};


//needs review for slowness -- for sidebar
export const assignMultiplePermissions = async(payload, sysModId, roleId) => {
    try {
        return await axios.post(`${baseAPI}/permission/assign-multiple-permissions/${sysModId}/${roleId}`, payload, Header);
    } catch (error) {
        console.error('Error assigning multiple permissions:', error);
        throw error;
    }
};

//system permission
export const updateUserSystemPermission = async(params, id) => {
    try {
        return await axios.post(`${baseAPI}/system-permission/assign-permission/` + id, params, Header);
    } catch (error) {
        console.error('Error updating user system permission:', error);
        throw error;
    }
};

export const assignMultipleSystemPermissions = async(payload, sysModId, sysRoleId) => {
    try {
        return await axios.post(`${baseAPI}/system-permission/assign-multiple-permissions/${sysModId}/${sysRoleId}`, payload, Header);
    } catch (error) {
        console.error('Error assigning multiple system permissions:', error);
        throw error;
    }
};

export const authUserSystemPermissions = async() => {
    try {
        return await axios.get(`${baseAPI}/system-permission/user`, Header);
    } catch (error) {
        // console.error('Error getting user system permission:', error);
        // throw error;
    }
};


export const systemModuleInfo = async(id) => {
    try {
        return await axios.get(`${baseAPI}/system-permission/module-details/${id}`, Header);
    } catch (error) {
        // console.error('Error finding system module:', error);
        // throw error;
    }
};


//PERMISSION HEADERS
export const permissionHeaders = async() => {
    try {
        return await axios.get(`${baseAPI}/permission-headers`, Header);
    } catch (error) {
        console.error('Error fetching permission headers:', error);
        throw error;
    }
};

export const basicPermissionHeaders = async() => {
    try {
        return await axios.get(`${baseAPI}/permission-headers/basic`, Header);
    } catch (error) {
        console.error('Error fetching basic permission headers:', error);
        throw error;
    }
};
import axios from "axios";
import config from "@/config"
import toastMsg from "@/utils/messages/toastMsg";
const { baseAPI, Header } = config;


export const getClientAssessmentDashboardData = async (params) => {
    try {
        const { type, period } = params;
        const res = await axios.get(`${baseAPI}/nurse-assessment/data/dashboard?type=${type}&period=${period}`, Header);
        return res;
    } catch (error) {
        return error
    }
}

export const getAllClientAssessments = async () => {
    try {
        return await axios.get(`${baseAPI}/nurse-assessment/assessments`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const getNurseAssessmentReq = async () => {
    try {
        return await axios.get(`${baseAPI}/nurse-assessment`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}
export const showNurseAssessmentReq = async (id) => {
    try {
        return await axios.get(`${baseAPI}/nurse-assessment/assessment-detail/${id}`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}
export const createNurseAssessmentReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/nurse-assessment/create`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const deleteNurseAssessmentReq = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/nurse-assessment/delete/${id}`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}
export const reassignNurseAssessmentReq = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/nurse-assessment/reassign/${id}`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}
export const updateNurseAssessmentReq = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/nurse-assessment/update/${id}`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}





// Investigation 
export const getAssessmentInvestigationReq = async () => {
    try {
        return await axios.get(`${baseAPI}/nurse-assessment/investigation`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}


export const saveInvestigationReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/nurse-assessment/investigation`, payload, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const fetchIndexDropdowns = async () => {
    try {
        return await axios.get(`${baseAPI}/ca/categories`, Header);
    } catch (error) {
        return error
    }
}

 

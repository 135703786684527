import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

// urls endpoints for Peoples form
const requestURI = 'people_management/forms/submitted';

// 
export const fetchPeopleSubmittedFormsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        throw error.response || error;
    }
}





import * as api from '@/utils/services/reports/setup/types/dashboard';

const state = {
    report_dashboard: {},
}

const getters = {
    listReportDashboardData: state => state.report_dashboard
}

const actions = {

    async getReportDashboardDataReq({ commit }, params) {
        const res = await api.getReportDashboardDataReq(params);
        console.log(res, "response")
        if (res && res.data) {
          commit('setReportDashboardData', res.data);
        } else {
          console.log("getReportDashboardDataReq returned null or undefined");
          commit('setReportDashboardData', res);
        }
    },

}

const mutations = {
    setReportDashboardData(state, data) {
        state.report_dashboard = data
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}
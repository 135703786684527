import axios from "axios";
import config from "@/config"
import toastMsg from "@/utils/messages/toastMsg";
const { baseAPI, Header } = config;

// urls endpoints for Peoples form
const requestURI = 'people_management/form-builder/sections';
// const requestQuestionsUrl ='';

// save People set-up form
export const savePeopleSectionFormRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        // return error
        throw error.response || error;
    }
}

// 
export const fetchPeopleSectionFormsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        throw error.response || error;
    }
}

// 
export const fetchPeopleSectionFormRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        throw error.response || error;
    }
}

export const fetchPeopleSubsectionFormIdRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        throw error.response || error;
    }
}

// 
export const updatePeopleSectionFormRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/update`, payload, Header);
    } catch (error) {
        throw error.response || error;
    }
}

// 
export const deletePeopleSectionFormRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/delete`, payload, Header);
    } catch (error) {
        throw error.response || error;
    }

}


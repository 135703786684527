export const getters = {
    // Form data getters
    getAllForms: state => state.forms,
    getCurrentForm: state => state.currentForm,
    getFormSetup: state => state.formSetup,
    // getFormSections: state => state.formSetup.sections, //already located inside the store/forms/formGetters.js


    // UI state getters
    isLoading: state => state.isLoading,
    getError: state => state.error,
    getCurrentStep: state => state.currentStep,

    // Dropdown getters
    getProgramCategories: state => state.dropdownLists.program_category,
    getProgramTypes: state => state.dropdownLists.program_type,

    // Computed getters
    getFormCount: state => state.forms.length,
    getFormById: state => id => state.forms.find(form => form.id === id),
    getFilteredForms: state => (filter) => {
        return state.forms.filter(form => {
            return form.name.toLowerCase().includes(filter.toLowerCase());
        });
    },

    getPeopleSectionFormByIdData: state => state.peopleSectionFormByIdData,
    // getPeopleSectionFormByIdData
};

export default getters;
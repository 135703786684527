import axios from "axios";
import config from "@/config";
const { baseAPI, Header } = config;

// urls endpoints for Peoples form
const requestURI = 'people_management/form-builder/forms';
// const requestQuestionsUrl ='';

// save People set-up form
export const savePeopleSetupFormRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        // return error
        throw error.response || error;
    }
}

// 
export const fetchPeopleSetupFormsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        throw error.response || error;
    }
}

// 
export const fetchPeopleSetupFormRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        throw error.response || error;
        // toastMsg(error.response.data.message, "error");
        // return error
    }
}

// 
export const updatePeopleSetupFormRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/update`, payload, Header);
    } catch (error) {
        throw error.response || error;
        // return error
    }
}

// 
export const deletePeopleSetupFormRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/delete`, payload, Header);
    } catch (error) {
        throw error.response || error;
        // return error
    }

}

// 
export const submitPeopleFormRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/submit`, payload, Header);
    } catch (error) {
        return error
    }
}

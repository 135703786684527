const base = "/people-management/form-builder";

// people_management form builder routes  - vendor-management-form builder folder structure in the backend
export default [
    {
        path: `${base}`,
        name: 'PeopleFormBuilder',
        component: () => import('@/views/people_management/formBuilder/index.vue'),
        meta: {
            requiresAuth: true
        }
    },
];

//TO DO: Import Services
import { getRoleUser } from '../../utils/services/permission/index';

import {
storeForm, updateForm,
  getUserGroups,
  getDataCategories, addDataCategory,
  getScoringCategories, addScoringCategory,
  getFormTaskModules,
  getFormGroups,
  getTaskCategories,
  getFormTaskTypes,
  createSection, deleteSection,
  createSubsection, deleteSubsection,
  createQuestion, deleteQuestion,
  saveSection, getSections,
  getTaskSections, getTaskFormSections, getTaskFormSubSections, getTaskFormQuestions, getTaskFormQuestionOptions,
  getForms, getFillableForms, getForm, getSubmissions,
  saveFormDetails, saveFormSection, saveFormSubSection, saveFormQuestion, saveFormDimension, updateFormDetails, updateFormSection,
  saveFormReviewData,
  getQuestionTypes,
  getFeedbackForms, getFollowUpForms,
  getFormReviews,
  saveFormResponseDetails,
  approveForm, getFormList, formDropdownsRequest,
  getParentQuestions, getParentOptions,
    
    
} from "@/utils/services/forms";


//UPDATE 1

export const actions = {


  async fetchForms({ commit }, payload) {
    const res = await getForms(payload);
    commit('setForms', res.data)
  },


  async fetchFormList({ commit }, payload) {
    const res = await getFormList();
    commit('setForms', res.data)
  },
  

  async fetchSubmissions({ commit }, payload) {
    const res = await getSubmissions(payload);
    commit('setSubmissions', res.data)
  },

  async saveFormDetails({ commit }, formDetails) {
    try {
      const response = await saveFormDetails(formDetails);
      return response;
    } catch (error) {
      console.error('Failed to save form details:', error);
      throw error;
    }
  },

  async updateFormDetails({ commit }, formDetails) {
    try {
      const response = await updateFormDetails(formDetails);
      return response;
    } catch (error) {
      console.error('Failed to save form details:', error);
      throw error;
    }
  },

  async saveFormReviewData({ commit }, formDetails) {
    try {
      const response = await saveFormReviewData(formDetails);
      return response;
    } catch (error) {
      console.error('Failed to save form review:', error);
      throw error;
    }
  },


  async approveForm({ commit }, form_id) {
    try {
      const response = await approveForm(form_id);
      // Perform any necessary mutations or actions with the response data

      return response;
    } catch (error) {
      console.error('Failed to save form details:', error);
      throw error;
    }
  },



  async saveFormSection({ commit }, formSections) {
    const response = await saveFormSection(formSections);
    return response;
  },

  async saveFormSubSection({ commit }, formSections) {
    const response = await saveFormSubSection(formSections);
    return response;
  },

  async saveFormQuestion({ commit }, { questionlayout }) {
    try {
      const response = await saveFormQuestion(questionlayout);
      return response;
    } catch (error) {
      console.error("Error while creating question:", error);
      throw error;
    }
  },

  async saveFormDimension({ commit }, { sectionslayout }) {
    try {
      const response = await saveFormDimension(sectionslayout);
      return response;
    } catch (error) {
      console.error("Error while creating dimension:", error);
      throw error;
    }
  },

  async updateFormSection({ commit }, formSections) {
    const response = await updateFormSection(formSections);
    return response;
  },


  async saveFormResponseDetails({ commit }, formResponses) {
    const res = await saveFormResponseDetails(formResponses);

    return res;
  },

  async fetchForm({ commit }, form_id) {
    const res = await getForm(form_id);
    commit('setForm', res.data);
    return res.data.form;
  },

  //add Delete functionality


  async fetchTaskFormSections({ commit }, form_id) {
    const res = await getTaskFormSections(form_id);
    commit('setFormSections', res);
    return res;
  },

  async fetchTaskFormSubSections({ commit }, section_id) {
    const res = await getTaskFormSubSections(section_id);
    commit('setFormSubSections', res.data);
    return res.data;
  },

  async fetchTaskFormQuestions({ commit }, subsection_id) {
    const res = await getTaskFormQuestions(subsection_id);
    commit('setFormQuestions', res.data);
    return res.data;
  },

  async fetchTaskFormQuestionOptions({ commit }, question_id) {
    const res = await getTaskFormQuestionOptions(question_id);
    commit('setFormQuestionOptions', res.data);
    return res.data;
  },

  
  async fetchFillableForm ({ commit },form_id ) {   
    const res = await getFillableForms(form_id);    
    commit('setFillableForm', res.data)
	},



    async fetchUserGroups({ commit }, payload) {
        const res = await getUserGroups(payload);
        commit('setUserGroups', res.data)
    },

    async fetchScoringCategory({ commit }, payload) {
        const res = await getScoringCategories(payload);
        commit('setScoringCategories', res.data)
    },


  async fetchDataCategories({ commit }, payload) {
      const res = await getDataCategories(payload);
      commit('setDataCategories', res.data)
  },



  async fetchRoleUsers({commit}, roleId) {

    const res = await getRoleUser(roleId)
    commit('setRoleUser', res.data)

  },




  async fetchUserGroups({ commit }, payload) {
    const res = await getUserGroups(payload);
    commit('setUserGroups', res.data)
  },

 

  async fetchDataCategories({ commit }, payload) {
    const res = await getDataCategories(payload);
    commit('setDataCategories', res.data)
  },


  async fetchFormTaskModules({ commit }, payload) {
    const res = await getFormTaskModules(payload);
    commit('setTaskModules', res.data)
  },

  async fetchFormGroups({ commit }, payload) {
    const res = await getFormGroups(payload);
    
   commit('setFormGroups', res.data)
  },


  async fetchTaskCategories({ commit }, payload) {
    try {
      const res = await getTaskCategories(payload);
      commit('setTaskCategories', res.data);
    } catch (error) {
      // Handle error
      console.error(error);
      throw error;
    }
  },

  async fetchFormTaskTypes({ commit }, payload) {
    try {
      const res = await getFormTaskTypes(payload);
      commit('setFormTaskTypes', res.data);
    } catch (error) {
      // Handle error
      console.error(error);
      throw error;
    }
  },



  async fetchSections({ commit }, payload) { //using for new form
    try {
      const res = await getSections(payload);

      // console.log("fetchSections", res.data);
      commit('setSections', res.data);
      


      // console.log("SECTIONS FOR NEW FORM: ", res.data);
    } catch (error) {
      // Handle error
      console.error(error);
      throw error;
    }
  },


  async fetchFormSections({ commit }, payload) {
    try {
      const res = await getTaskSections(payload);
      commit('setFormSections', res.data);
    } catch (error) {
      // Handle error
      console.error(error);
      throw error;
    }
  },

  
  async fetchQuestionTypes({ commit }, payload) {
    const res = await getQuestionTypes(payload);
    commit('setQuestionTypes', res.data)
  },

  async fetchParentQuestions({ commit }, payload) {
    const res = await getParentQuestions(payload);

    // console.log("fetchParentQuestions", res);
    
    commit('setParentQuestions', res.data);

    return res.data;
  },


  async fetchParentOptions({ commit }, payload) {
    const res = await getParentOptions(payload);
    commit('setParentOptions', res.data);
    return res.data;
  },


  async fetchFeedbackForms({ commit }, payload) {
    const res = await getFeedbackForms(payload);
    commit('setFeedbackForms', res.data)
  },

  async fetchFollowUpForms({ commit }, payload) {
    const res = await getFollowUpForms(payload);
    commit('setFollowUpForms', res.data)
  },


  async fetchFormReviews({ commit }, payload) {
    const res = await getFormReviews(payload);
    commit('setFormReviews', res.data);
    return res.data;
  },



  



  ///////////

  async fetchTaskSections({ commit }, payload) {
    try {
      const res = await getTaskSections(payload);
      commit('setTaskSections', res.data);
    } catch (error) {
      // Handle error
      console.error(error);
      throw error;
    }
  },


//SETTINGS

async storeScoringCategory({ commit }, scoringCategory) {
  try {
    const response = await addScoringCategory(scoringCategory);
    // Perform any necessary mutations or actions with the response data

    return response;
  } catch (error) {
    console.error('Failed to save Scoring Category:', error);
    throw error;
  }
},


async getFormDropdowns({ commit }) {
    try {
    const res = await formDropdownsRequest();
    commit('setFormDropdowns', res);

    return res;
} catch (error) {
  console.error('Failed to save Scoring Category:', error);
  throw error;
}
},










}
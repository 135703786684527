import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

export const getReportDashboardDataReq = async (params) => {
    try {
        // return await axios.get(`${baseAPI}/metrics-data/happiness-data?type=${type}&period=${period}`, Header);

        const { type, period, startDate, endDate } = params;
        let url = `${baseAPI}/report-setup/dashboard?type=${type}&period=${period}`;
        
        if (startDate) {
            url += `&startDate=${startDate}`;
        }
        
        if (endDate) {
            url += `&endDate=${endDate}`;
        }

        const res = await axios.get(url, Header);

        return res;
    } catch (error) {
        return error
    }
}
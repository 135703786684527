const base = "/people-management/settings"

// people_management settings routes  - vendor-management-settings folder structure in the backend
export default [
    // people management settings/index
    {
        path: `${base}`,
        name: 'PeopleManagementSettings',
        component: () => import('@/views/people_management/settings/index.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // people management settings - user category
    {
        path: `${base}/user-category`,
        name: 'PeopleManagementUserCategorySettings',
        component: () => import('@/views/people_management/settings/UserCategory.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // people management settings - user role
    {
        path: `${base}/user-type`,
        name: 'PeopleManagementUserTypeSettings',
        component: () => import('@/views/people_management/settings/UserType.vue'),
        meta: { requiresAuth: true }
    },
    // people management settings - form type
    {
        path: `${base}/form-type`,
        name: 'PeopleManagementFormTypeSettings',
        component: () => import('@/views/people_management/settings/FormType.vue'),
        meta: { requiresAuth: true }
    },
    // people management settings - form status
    {
        path: `${base}/form-status`,
        name: 'PeopleManagementFormStatusSettings',
        component: () => import('@/views/people_management/settings/FormStatus.vue'),
        meta: { requiresAuth: true }
    },
    // people management settings - conditional questions
    {
        path: `${base}/conditional-question`,
        name: 'PeopleManagementConditionalQuestionSettings',
        component: () => import('@/views/people_management/settings/ConditionalQuestion.vue'),
        meta: { requiresAuth: true }
    },
];

import form_builder from './form_builder';
import forms from './forms';
// import { state } from './state';
// import { mutations } from './mutations';
// import { actions } from './actions';
// import { getters } from './getters';

export default {
    namespaced: false,
    modules: {
        form_builder,
        forms
    },
}
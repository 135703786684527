import { checkPermissions } from '@/utils/routeService/useCheckPermissions';

export default [
    {
            path: '/adherence-tracker/dashboard',
            name: 'AdherenceTrackerDashboard',
            component: () => import('@/views/adherence_tracker/dashboard/index.vue'),
            meta: {
                // requiresAuth: true,
                title: 'Adherence Tracker Dashboard',
                check: 'show_task',
            },
            beforeEnter: checkPermissions,
    }
];
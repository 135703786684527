import axios from 'axios';
import config from "@/config";
const { baseAPI, Header, AuthHeader } = config;

export const getHomecareworker = async () => {
    try {
        return await axios.get(`${baseAPI}/homecareworkers`, Header);
    } catch (error) {
        // console.error('Error getting homecare worker:', error);
        throw error;
    }
};

export const postHomecareworker = async (params) => {
    try {
        return await axios.post(`${baseAPI}/homecareworkers`, params, Header);
    } catch (error) {
        console.error('Error posting homecare worker:', error);
        throw error;
    }
};

export const updateHomecareworker = async (params) => {
    try {
        return await axios.post(`${baseAPI}/homecareworkers/${params['id']}/update`, params, Header);
    } catch (error) {
        console.error('Error updating homecare worker:', error);
        throw error;
    }
};

export const deleteHomecareworker = async (id) => {
    try {
        return await axios.post(`${baseAPI}/homecareworkers/${id}/delete`, null, Header);
    } catch (error) {
        console.error('Error deleting homecare worker:', error);
        throw error;
    }
};

export const homecareworkerDetails = async (id) => {
    try {
        return await axios.get(`${baseAPI}/homecareworkers/${id}`, Header);
    } catch (error) {
        console.error('Error getting homecare worker details:', error);
        throw error;
    }
};

export const homecareworkerMgtDetails = async (id) => {
    try {
        return await axios.get(`${baseAPI}/homecareworkers/${id}/details`, Header);
    } catch (error) {
        console.error('Error getting homecare worker details:', error);
        throw error;
    }
};

export const getHomecareworkersDashboardData = async (params) => {
    try {
        const { type, period, startDate, endDate } = params;
        let url = `${baseAPI}/homecareworkers/data/dashboard?type=${type}&period=${period}`;

        if (startDate) {
            url += `&startDate=${startDate}`;
        }

        if (endDate) {
            url += `&endDate=${endDate}`;
        }

        const res = await axios.get(url, Header);

        return res;
    } catch (error) {
        console.error('Error getting homecareworkers dashboard details:', error);
        throw error;
    }
}

export const importHomecareworkers = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/homecareworkers/import`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
};
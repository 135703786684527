import settings from "./settings"
import formBuilder from "./formBuilder"

import {
    checkPermissions
} from '@/utils/routeService/useCheckPermissions';

export default [
    {
        path: '/people-management/dashboard',
        name: 'PeopleManagementDashboard',
        component: () => import('@/views/people_management/dashboard/index.vue'),
        // meta: {
        //     check: 'show_users',
        // },
        // beforeEnter: checkPermissions,
    },
    {
        path: '/people-management/all',
        name: 'AllPeopleManagement',
        component: () => import('@/views/people_management/log/PeopleManagementTable.vue'),
        // meta: {
        //     check: 'show_people_management',
        // },
        // beforeEnter: checkPermissions,
    },
    // 
    {
        path: '/people-management/create',
        name: 'CreatePeopleManagement',
        component: () => import('@/views/people_management/form/index.vue'),
        // meta: {
        //     check: 'show_people',
        // },
        // beforeEnter: checkPermissions,
    },
    // 
    {
        path: '/people-management/edit/:id',
        name: 'EditPeopleManagement',
        component: () => import('@/views/people_management/form/edit.vue'),
        // meta: {
        //     check: 'show_people',
        // },
        // beforeEnter: checkPermissions,
    },

    //  
    ...settings,
    ...formBuilder
]
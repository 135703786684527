export const mutations = {
    setActiveForms(state, form) {
		state.activeFormsList = form
	},

	setForms(state, forms) {
		state.formsList = forms
	},

	setForm(state, form) {
		state.form = form;
	  },
	  
	  setFormSections(state, form) {
		state.formSections = form;
	  },

	  setSections(state, form) {
		state.sections = form;
	  },
	  

	  setFormSubSections(state, section) {
		state.formSubSections = section;
	  },

	  setFormQuestions(state, subsection) {
		state.formQuestions = subsection;
	  },

	  setFormQuestionOptions(state, question) {
		state.formQuestionOptions = question;
	  },

	setSubmissions(state,submissions) {
		state.submissionsList = submissions
	},

	setFormTypes(state, formTypes) {
		state.formTypesList = formTypes
	},

	setDraftForms(state, form) {
		state.draftFormsList = form
	},

	setFillableForm(state, fillableForm) {
		state.fillableForm = fillableForm
	},

	setFieldsInventory(state, fieldsInventory) {
		state.fieldsInventoryList = fieldsInventory
	},

	setFormFunctions(state, functions) {
		state.formFunctionsList = functions
	},

	setDataCategories(state,dataCategories) {
		state.dataCategoriesList = dataCategories
	},

	setScoringCategories(state,scoringCategories) {
		state.scoringCategoriesList = scoringCategories
	},

	setFormTaskTypes(state,formTaskTypes) {
		state.formTaskTypesList = formTaskTypes
	},
	setFormGroups(state,formGroups) {
		state.formGroupsList = formGroups
	},

	setTaskModules(state,taskModules) { 
		state.taskModulesList = taskModules
	},

	setQuestionTypes(state,questionTypes) { 
		state.questionTypesList = questionTypes
	},

	setParentQuestions(state,parentQuestions) {
		state.parentQuestionsList = parentQuestions
	},

	setParentOptions(state,parentOptions) {
		state.parentOptionsList = parentOptions
	},


	setFeedbackForms(state,feedbackForms) { 
		state.feedbackFormList = feedbackForms
	},
	
	setFollowUpForms(state,followUpForms) { 
		state.followUpFormList = followUpForms
	},
	
	

	setTaskCategories(state,taskCategories) {
		state.taskCategoriesList = taskCategories
	},

	setFormSections(state,formSections) {
		state.formSectionList = formSections
	},

	setSections(state,sections) {
		state.sectionList = sections
	},



	setUserGroups(state,userGroups) {
		state.userGroupsList = userGroups
	},

	setFormReviews(state,formReviews) {
		state.formReviewsList = formReviews
	},

	setTaskFormSections(state, sections) {
		state.formSections = sections;
	  },


      setFormDropdowns(state, payload) {
        state.form_dropdowns = payload;
    }, 



	//////////

	setSection(state, section) {
		state.section = section;
	  },

	saveSection(state, section) {
		// Add the new section to the state
		state.sections.push(section);
	  },

	  removeSection(state, sectionId) {
		// Find the index of the section in the state using the sectionId
		const index = state.sections.findIndex(section => section.id === sectionId);
		if (index !== -1) {
		  // Remove the section from the state
		  state.sections.splice(index, 1);
		}
	  },

/*	updateSection(state, { sectionId, property, value }) {
		// Find the section in the state using the sectionId
		const section = state.sections.find(section => section.id === sectionId);
		if (section) {
		  // Update the specified property of the section
		  section[property] = value;
		}
	  },
*/

		// Set the subsections for a section
		setSubsections(state, subsections) {
			state.subsections = subsections;
		},
		
		// Add a new subsection
		addSubsection(state, subsection) {
			state.subsections.push(subsection);
		},
		
		// Remove a subsection
		removeSubsection(state, subsectionId) {
			state.subsections = state.subsections.filter(subsection => subsection.id !== subsectionId);
		},

		// Set the questions for a subsection
		setQuestions(state, questions) {
			state.questions = questions;
		},
		
		// Add a new question
		addQuestion(state, question) {
			state.questions.push(question);
		},
		
		// Remove a question
		removeQuestion(state, questionId) {
			state.questions = state.questions.filter(question => question.id !== questionId);
		},


  
  
}

import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

//UPDATE 2

export const getUserGroups = async () => {
  try {
    return await axios.get(`${baseAPI}/form/get-user-groups`, Header);
  } catch (error) {

    return error
  }
}



//Add a store for sub forms to feedback forms table
export const storeForm = async (payload) => {
  try {
    return await axios.post(`${baseAPI}/form/store-form`, payload, Header);
  } catch (error) {
    return error
  }
}



export const getForm = async (form_id) => {
  try {

    const resp = await axios.get(`${baseAPI}/form/edit-form/` + form_id, Header);

    return resp;
  } catch (error) {

    return error
  }
}

export const fetchReportFormReq = async () => {
  try {

    const resp = await axios.get(`${baseAPI}/form/fetch-report-dashboard-forms`, Header);

    return resp;
  } catch (error) {

    return error
  }
}

export const getTaskFormSections = async (id) => {
  try {

    const resp = await axios.get(`${baseAPI}/form/get-task-form-sections/` + id, Header);

    return resp.data;
  } catch (error) {

    return error
  }
}

export const getTaskFormSubSections = async (section_id) => {
  try {

    const resp = await axios.get(`${baseAPI}/form/get-task-form-subsections/` + section_id, Header);

    return resp;
  } catch (error) {

    return error
  }
}

export const getTaskFormQuestions = async (subsection_id) => {
  try {

    const resp = await axios.get(`${baseAPI}/form/get-task-form-questions/` + subsection_id, Header);

    return resp;
  } catch (error) {

    return error
  }
}

export const getTaskFormQuestionOptions = async (question_id) => {
  try {

    const resp = await axios.get(`${baseAPI}/form/get-task-form-question-options/` + question_id, Header);

    return resp;
  } catch (error) {

    return error
  }
}

export const getFormSections = async () => {
  try {
    return await axios.get(`${baseAPI}/form/get-form-sections`, Header);
  } catch (error) {
    if (error.response && error.response.status === 429) {
      await delay(1000); // Introduce a delay before retrying
      return getFormSections();

    } else {
      console.error('Error retrieving form sections: ', error);
      throw new Error('Failed to retrieve form sections. Please try again.'); // Throw a custom error or return an error message   
    }
  }
}


export const getFillableForms = async (form_id) => {
  try {
    return await axios.get(`${baseAPI}/form/rendering/${form_id}`, Header);
  } catch (error) {

    return error
  }
}







/*==================================================*/
/* SCORING CATEGORIES
/*==================================================*/


export const getScoringCategories = async () => {
  try {
    return await axios.get(`${baseAPI}/form/get-scoring-categories`, Header);
  } catch (error) {
    return error
  }
}

export const addScoringCategory = async (params) => {
  try {
    return await axios.post(`${baseAPI}/form/add-scoring-category`, params, Header);
  } catch (error) {
    return error
  }
}

export const updateScoringCategory = async (params) => {
  try {
    return await axios.post(`${baseAPI}/form/update-scoring-category`, params, Header);
  } catch (error) {
    return error
  }
}


export const deleteScoringCategory = async (id) => {
  try {
    return await axios.post(`${baseAPI}/form/delete-scoring-category`, id, Header);
  } catch (error) {
    return error
  }
}




/*==================================================*/
/* DATA CATEGORIES
/*==================================================*/


export const getDataCategories = async () => {
  try {
    return await axios.get(`${baseAPI}/form/get-data-categories`, Header);
  } catch (error) {
    return error
  }
}




/*==================================================*/
/* TASK MODULES
/*==================================================*/

export const addTaskModule = async (params) => {
  try {
    return await axios.post(`${baseAPI}/form/add-task-module`, params, Header);
  } catch (error) {
    return error
  }
}

export const getFormTaskModules = async () => {
  try {
    return await axios.get(`${baseAPI}/form/get-task-modules`, Header);
  } catch (error) {
    return error
  }
}

export const updateTaskModule = async (params) => {
  try {
    return await axios.post(`${baseAPI}/form/update-task-module`, params, Header);
  } catch (error) {
    return error
  }
}

export const deleteTaskModule = async (id) => {
  try {
    return await axios.post(`${baseAPI}/form/delete-task-module`, id, Header);
  } catch (error) {
    return error
  }
}


/*==================================================*/
/* TASK FORM GROUPS
/*==================================================*/

export const getFormGroups = async () => {
  try {
    return await axios.get(`${baseAPI}/form/get-form-groups`, Header);
  } catch (error) {
    return error
  }
}




export const getForms = async () => {
  try {

    const res = await axios.get(`${baseAPI}/form/fetch-forms`, Header);

    return res;
  } catch (error) {
    console.error('Failed to get forms:', error);
    return error;
  }
}

export const getFormList = async () => {
  try {

    const res = await axios.get(`${baseAPI}/form/fetch-form-list`, Header);

    return res;
  } catch (error) {
    console.error('Failed to get forms:', error);
    return error;
  }
}

export const getSubmissions = async () => {
  try {

    const res = await axios.get(`${baseAPI}/form/fetch-submissions`, Header);

    return res;
  } catch (error) {
    console.error('Failed to get forms:', error);
    return error;
  }
}

export const saveFormDetails = async (payload) => {
  try {

    const resonse = await axios.post(`${baseAPI}/form/save-form`, payload, Header);
    return resonse;
  } catch (error) {
    console.error('Error creating Form:', error);
    throw error;
  }
}



export const saveFormReviewData = async (payload) => {
  try {

    const resonse = await axios.post(`${baseAPI}/form/save-form-review`, payload, Header);
    return resonse;
  } catch (error) {
    console.error('Error Submitting Form for Review:', error);
    throw error;
  }
}



export const updateFormDetails = async (payload) => {
  try {
    return await axios.post(`${baseAPI}/form/update-form`, payload, Header);
  } catch (error) {
    return error
  }
}

export const approveForm = async (payload) => {
  try {

    const resonse = await axios.post(`${baseAPI}/form/approve-form`, payload, Header);
    return resonse;
  } catch (error) {
    console.error('Error creating Form:', error);
    throw error;
  }
}

/*==================================================*/
/* TASK CATEGORIES
/*==================================================*/

export const getTaskCategories = async () => {
  try {
    return await axios.get(`${baseAPI}/form/get-task-categories`, Header);
  } catch (error) {
    return error
  }
}

/*==================================================*/
/* TASK TYPES
/*==================================================*/

export const getFormTaskTypes = async () => {
  try {
    return await axios.get(`${baseAPI}/form/get-form-task-types`, Header);
  } catch (error) {
    return error
  }
}

/*==================================================*/
/* SECTIONS
/*==================================================*/



//for specific form
export const getSections = async (form_id) => {
  try {
    return await axios.get(`${baseAPI}/form/get-sections/` + form_id, Header);
  } catch (error) {
    return error
  }
}


export const getTaskSections = async () => {
  try {
    return await axios.get(`${baseAPI}/form/get-task-sections`, Header);
  } catch (error) {
    return error
  }
}

export const getQuestionTypes = async () => {
  try {
    return await axios.get(`${baseAPI}/form/get-question-types`, Header);
  } catch (error) {
    return error
  }
}



export const getParentQuestions = async (formId) => {
  try {
    return await axios.get(`${baseAPI}/form/get-parent-questions/` + formId, Header);
  } catch (error) {
    return error
  }
}


export const getParentOptions = async (questionId) => {
  try {
    return await axios.get(`${baseAPI}/form/get-parent-options/` + questionId, Header);
  } catch (error) {
    return error
  }
}


export const getFeedbackForms = async () => {
  try {
    return await axios.get(`${baseAPI}/form/get-feedback-forms`, Header);
  } catch (error) {
    return error
  }
}

export const getFollowUpForms = async () => {
  try {
    return await axios.get(`${baseAPI}/form/get-followup-forms`, Header);
  } catch (error) {
    return error
  }
}

export const getFormReviews = async (form_id) => {
  try {
    return await axios.get(`${baseAPI}/form/get-form-reviews/` + form_id, Header);
  } catch (error) {
    return error
  }
}


export const saveSection = async (payload) => {
  try {

    const resonse = await axios.post(`${baseAPI}/form/sections`, payload, Header);


    return resonse;


  } catch (error) {
    console.error('Error creating Section:', error);
    throw error;
  }
}








/////////////////////////////////////////////



// Create section
export async function createSection(sectionData) {
  try {
    const response = await axios.post(`${baseAPI}/form/save-sections`, sectionData, Header);

    return new Section(response.data);
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
}


// Save section
export const saveFormSectionOld = async (section) => {
  const response = await axios.post(`${baseAPI}/form/save-sections`, section, Header);

  return response.data;
};

export const saveFormSection = async (payload) => {
  try {

    const resonse = await axios.post(`${baseAPI}/form/save-form-section`, payload, Header);

    return resonse;
  } catch (error) {
    console.error('Error creating Form Sections:', error);

    throw error;
  }
}

export const saveFormSubSection = async (payload) => {
  try {

    const resonse = await axios.post(`${baseAPI}/form/save-form-subsection`, payload, Header);

    return resonse;
  } catch (error) {
    console.error('Error creating Form Sections:', error);

    throw error;
  }
}







export const updateFormSection = async (payload) => {
  try {

    const resonse = await axios.post(`${baseAPI}/form/update-form-section`, payload, Header);

    return resonse;
  } catch (error) {
    console.error('Error creating Form Sections:', error);

    throw error;
  }
}


export const saveFormResponseDetails = async (payload) => {
  try {

    const response = await axios.post(`${baseAPI}/form/save-form-responses`, payload, Header);
    // console.log('Form Response:', response)
    //console.log(response);

    return response;
  } catch (error) {
    console.error('Error creating Form Responses:', error);

    throw error;
  }
}

// Save Sub-section
export const saveFormSubSectionOLD = async (data) => {
  const response = await axios.post(`${baseAPI}/form/save-subsections`, data, Header);

  return response.data;
};

// Save Questions
export const saveFormQuestion = async (questionlayout) => {
  const response = await axios.post(`${baseAPI}/form/save-questions`, questionlayout, Header);

  return response.data;
};


// Save Dimension
export const saveFormDimension = async (sectionslayout) => {
  const response = await axios.post(`${baseAPI}/form/save-dimension`, sectionslayout, Header);

  return response.data;
};






// Delete section
export async function deleteSection(sectionId) {
  try {
    await axios.delete(`${baseAPI}/sections/${sectionId}`);
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
}

// Create subsection
export async function createSubsection(sectionId, subsectionData) {
  try {
    const response = await axios.post(`${baseAPI}/sections/${sectionId}/subsections`, subsectionData, Header);
    const newSubsection = response.data;
    return new Subsection(newSubsection);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// Delete subsection
export async function deleteSubsection(subsectionId) {
  try {
    await axios.delete(`${baseAPI}/subsections/${subsectionId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// Create question
// export async function createQuestion(subsectionId, questionData) {
//   try {
//     const response = await axios.post(`${baseAPI}/subsections/${subsectionId}/questions`, questionData, Header);
//     const newQuestion = response.data;
//     return new Question(newQuestion);
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// }

// Delete question
export async function deleteQuestion(questionId) {
  try {
    await axios.delete(`${baseAPI}/questions/${questionId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export const formDropdownsRequest = async () => {
  try {
    const res = await axios.get(`${baseAPI}/form/data/form-dropdown-list`, Header);
    return res;
  } catch (error) {
    // toastMsg(error.response.data.message, "error");
    return error
  }
}

export const getters = {
    getPeopleFormResponses: state => state.peopleFormResponses,
    getCurrentFormResponse: state => state.currentFormResponse,
    getPeopleFormQuestionResponse: state => state.peopleFormQuestionResponse,
    isSubmissionInProgress: state => state.formSubmissionInProgress,
    getFormResponseById: state => id => {
        return state.peopleFormResponses.find(form => form.id === id);
    }
};

export default getters;
import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

export const getHappinessDashboardDataReq = async (params) => {
    try {
        // return await axios.get(`${baseAPI}/metrics-data/happiness-data?type=${type}&period=${period}`, Header);

        const { type, period, startDate, endDate } = params;
        let url = `${baseAPI}/metrics-data/happiness-data?type=${type}&period=${period}`;

        if (startDate) {
            url += `&startDate=${startDate}`;
        }

        if (endDate) {
            url += `&endDate=${endDate}`;
        }

        const res = await axios.get(url, Header);

        return res;
    } catch (error) {
        return error
    }
}

export const getValueBaseDashboardDataReq = async (params) => {
    try {
        // return await axios.get(`${baseAPI}/metrics-data/value-base-data`, Header);

        const { type, period, startDate, endDate } = params;
        let url = `${baseAPI}/metrics-data/value-base-data?type=${type}&period=${period}`;

        if (startDate) {
            url += `&startDate=${startDate}`;
        }

        if (endDate) {
            url += `&endDate=${endDate}`;
        }

        const res = await axios.get(url, Header);

        return res;
    } catch (error) {
        return error
    }
}

export const getMetricDashboardDataReq = async (params) => {
    try {
        const { type, period, startDate, endDate } = params;
        let url = `${baseAPI}/metrics-data?type=${type}&period=${period}`;

        if (startDate) {
            url += `&startDate=${startDate}`;
        }

        if (endDate) {
            url += `&endDate=${endDate}`;
        }

        const res = await axios.get(url, Header);

        return res;
    } catch (error) {
        return error
    }
}

export const getProgramCategoryDataReq = async () => {
    try {
        return await axios.get(`${baseAPI}/metrics-data/program-categories`, Header);
    } catch (error) {
        return error
    }
}

export const getTaskTrendAnalysisDataRequest = async (params) => {
    try {
        const { user_id } = params;
        return await axios.get(`${baseAPI}/metrics-data/${user_id}/task-trend-analysis`, Header);
    } catch (error) {
        return error
    }
}
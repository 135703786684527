export const mutations = {
    // Form list mutations 
    SET_FORMS(state, forms) {
        state.forms = forms;
    },
    SET_CURRENT_FORM(state, form) {
        state.currentForm = form;
    },

    // Form setup mutations
    UPDATE_FORM_SETUP(state, formData) {
        state.formSetup = { ...state.formSetup, ...formData };
    },
    ADD_FORM_SECTION(state, section) {
        state.formSetup.sections.push(section);
    },
    UPDATE_FORM_SECTION(state, { index, section }) {
        state.formSetup.sections[index] = section;
    },
    DELETE_FORM_SECTION(state, index) {
        state.formSetup.sections.splice(index, 1);
    },

    // UI state mutations
    SET_LOADING(state, isLoading) {
        state.isLoading = isLoading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_CURRENT_STEP(state, step) {
        state.currentStep = step;
    },

    // Dropdown mutations
    SET_DROPDOWN_LISTS(state, { key, data }) {
        state.dropdownLists[key] = data;
    },
    UPDATE_DROPDOWN_LISTS(state, dropdownData) {
        state.dropdownLists = { ...state.dropdownLists, ...dropdownData };
    },

    setPeopleSectionFormByIdData(state, sections) {
        state.peopleSectionFormByIdData = sections;
    },
};

export default mutations;
import { checkPermissions } from '@/utils/routeService/useCheckPermissions';

export default [
    {
            path: '/notifications/dashboard',
            name: 'NotificationsDashboard',
            component: () => import('@/views/notifications/dashboard/index.vue'),
            meta: {
                // requiresAuth: true,
                title: 'Notifications Dashboard',
                check: 'show_task',
            },
            beforeEnter: checkPermissions,
    },
    {
            path: '/notifications/logs',
            name: 'NotificationsLogs',
            component: () => import('@/views/notifications/List.vue'),
            meta: {
                // requiresAuth: true,
                title: 'Notifications Logs',
                check: 'show_task',
            },
            beforeEnter: checkPermissions,
    },
    {
        path: '/notifications/settings',
        name: 'NotificationsSettings',
        component: () => import('@/views/notificationSettings/Details.vue'),
        meta: {
            // requiresAuth: true,
            title: 'Notifications Settings',
            check: 'show_task',
        },
        beforeEnter: checkPermissions,
}
];